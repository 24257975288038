import React, { useEffect, useState } from 'react';
import { Routes, Route, useNavigate, Link } from 'react-router-dom';

const baseUrl = process.env.REACT_APP_BASE_URL;

function SettingsPage({ supabase }) {
    const navigate = useNavigate()
    const [userSettings, setUserSettings] = useState({
        email: '',
        bevakningAktiv: false,
    });
    const [user, setUser] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const session = supabase.auth.getSession().then(({ data: { session } }) => {
                setUser(session.user);
            });
        };
        fetchData();
    }, []);

    const deleteUser = () => {
        const session = supabase.auth.getSession().then(({ data: { session } }) => {
            const bearer = session.access_token
            fetch(`${baseUrl}/user`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${bearer}`
                }
            })
                .then(() => {
                    // log out
                    supabase.auth.signOut();
                    // navigate to /map
                    navigate('/map');
                })
                .catch(error => console.error('Error:', error));
        });
    }

    const toggleBevakningar = () => {
        const newValue = !userSettings.bevakningAktiv;
        setUserSettings({ ...userSettings, bevakningAktiv: newValue })
        const session = supabase.auth.getSession().then(({ data: { session } }) => {
            const bearer = session.access_token
            fetch(`${baseUrl}/settings`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${bearer}`
                },
                body: JSON.stringify({ bevakningAktiv: newValue })
            })
                .then(() => {
                    // Update the bevakningar state to remove the deleted bevakning
                })
                .catch(error => console.error('Error:', error));
        });

    }

    return (
        <div>
            <h1>Inställningar</h1>
            <p>Hej {user?.email}</p>
        </div>
    )
}

export default SettingsPage;