import { useState, useEffect } from 'react';
import React from 'react';
import { Box, Typography, useTheme, useMediaQuery, CircularProgress, Skeleton } from '@mui/material';

export default function Summary({ historicalApartmentCount, historicalApartmentQueueDays, isLoadingMediatedApartments }) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [isReady, setIsReady] = useState(false);
    const [shortestQueueTime, setShortestQueueTime] = useState(null);
    const [averageQueueTime, setAverageQueueTime] = useState(null);

    useEffect(() => {
        if (!isLoadingMediatedApartments && historicalApartmentQueueDays) {
            const shortest = historicalApartmentQueueDays.min != null && !isNaN(historicalApartmentQueueDays.min)
                ? Math.floor(historicalApartmentQueueDays.min / 365)
                : null;

            const average = historicalApartmentQueueDays.median != null && !isNaN(historicalApartmentQueueDays.median)
                ? Math.floor(historicalApartmentQueueDays.median / 365)
                : null;

            setShortestQueueTime(shortest);
            setAverageQueueTime(average);

            // Only set isReady to true if we have valid data
            if (shortest !== null && average !== null) {
                setIsReady(true);
            }
        }
    }, [isLoadingMediatedApartments, historicalApartmentQueueDays]);

    return (
        <Box
            sx={{
                width: '100vw',
                position: 'relative',
                left: '50%',
                right: '50%',
                marginLeft: '-50vw',
                marginRight: '-50vw',
                overflow: 'hidden',
                py: isMobile ? 1 : 2,
                '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundImage: 'url("/stockholm_watercolor.webp")',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    filter: 'blur(1px)',
                    opacity: 0.4,
                    zIndex: 0,
                }
            }}
        >
            <Box sx={{
                position: 'relative',
                zIndex: 1,
                maxWidth: '600px',
                margin: '0 auto',
                px: 2,
                height: isMobile ? '120px' : '150px', // Fixed height
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center'
            }}>
                <Typography variant={isMobile ? "body2" : "body1"} component="div" gutterBottom align="center">
                    För denna sökning:
                </Typography>
                <Box display="flex" flexDirection="column" alignItems="center" mt={2}>
                    {!isReady ? (
                        <>
                            <Skeleton variant="text" width="60%" height={isMobile ? 32 : 40} />
                            <Skeleton variant="text" width="60%" height={isMobile ? 32 : 40} style={{ marginTop: '8px' }} />
                        </>
                    ) : (
                        <>
                            <Typography variant={isMobile ? "h6" : "h5"} component="div" color="primary" fontWeight="bold">
                                Kortast kötid: {shortestQueueTime} år
                            </Typography>
                            <Typography variant={isMobile ? "h6" : "h5"} component="div" color="primary" fontWeight="bold" mt={1}>
                                Men oftast runt: {averageQueueTime} år
                            </Typography>
                        </>
                    )}
                </Box>
            </Box>
        </Box>
    );
}