import './App.css';
import React, { useEffect, useState } from 'react';
import { Box, AppBar, Menu, MenuItem, Badge, Toolbar, IconButton, Button } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import Map from './components/map';
import PrivacyPolicy from './components/privacypolicy';
// import Login from './components/login';
import Bevakningar from './components/bevakningpage';
import { filterApartments } from './components/helpers'
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import LandingPage from './components/landingpage';
import NewLandingPage from './components/newlandingpage';
import SettingsPage from './components/settingspage';
import CookieConsent from "react-cookie-consent";
import { filterStartState } from './components/helpers'
import Login2 from './components/login2';
import Login from './components/login';
import SuccessPage from './components/successpage';
import SupportPage from './components/supportPage';
import WelcomePage from './components/welcomepage';
const baseUrl = process.env.REACT_APP_BASE_URL;
const supabase = require('./misc/supabasestuff').default;

function App() {

  const navigate = useNavigate();
  const [apartments, setApartments] = useState([]);
  const [currentFilter, setCurrentFilter] = useState(filterStartState);
  const [allApartments, setAllApartments] = useState([]);
  const [historicalApartments, setHistoricalApartments] = useState([]);
  const [currentApartments, setCurrentApartments] = useState([]);
  const [showCurrent, setShowCurrent] = useState(true);
  const [currentNoOfApartments, setCurrentNoOfApartments] = useState({ lägenheter: 0, adresser: 0 });
  const [session, setSession] = useState(null)
  const [snackOpen, setSnackOpen] = React.useState(false);
  const [snackMessage, setSnackMessage] = React.useState('');
  const [numberOfFiltersInUse, setNumberOfFiltersInUse] = useState(0);
  const [historicalApartmentCount, setHistoricalApartmentCount] = useState(0);
  const [historicalApartmentQueueDays, setHistoricalApartmentQueueDays] = useState({});
  const [isLoadingMediatedApartments, setIsLoadingMediatedApartments] = useState(true);
  const location = useLocation();

  const scrollToTop = () => {
    if (location.pathname !== '/') {
      navigate('/');
    }
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  useEffect(() => {
    const count = Object.keys(currentFilter).reduce((count, key) => {
      if (Array.isArray(currentFilter[key]) && Array.isArray(filterStartState[key])) {
        // If both are arrays, compare their lengths
        return count + (currentFilter[key].length !== filterStartState[key].length ? 1 : 0);
      } else {
        // If not, compare them directly
        return count + (currentFilter[key] !== filterStartState[key] ? 1 : 0);
      }
    }, 0);
    setNumberOfFiltersInUse(count);
  }, [currentFilter]);


  useEffect(() => {
    fetch(`${baseUrl}/apartments`)
      .then(response => response.json())
      .then(data => {
        setCurrentApartments(data);
        setAllApartments(data);
        // const noOfApartments = myapartments.reduce((acc, curr) => acc + curr.Antal, 0);
        // setCurrentNoOfApartments({ lägenheter: noOfApartments, adresser: myapartments.length });
      });
  }, []);

  useEffect(() => {
    fetch(`${baseUrl}/apartments?status=all`)
      .then(response => response.json())
      .then(data => {
        setHistoricalApartments(data);
        setIsLoadingMediatedApartments(false);
        // setAllApartments(myapartments);
      });
  }, []);

  const calculateQueueDays = (apartments) => {
    if (!apartments || apartments.length === 0) {
      return { min: null, max: null, median: null };
    }

    const validQueueDays = apartments
      .map(apartment => apartment.KotidDagar)
      .filter(days => typeof days === 'number' && !isNaN(days));

    if (validQueueDays.length === 0) {
      return { min: null, max: null, median: null };
    }

    const medianQueueTime = validQueueDays.sort((a, b) => a - b)[Math.floor(validQueueDays.length / 2)];


    return {
      min: Math.min(...validQueueDays),
      max: Math.max(...validQueueDays),
      median: medianQueueTime,
    };
  };

  // Usage in your component:
  useEffect(() => {
    try {
      let filteredApartments = filterApartments(allApartments, currentFilter);
      const filteredHistorical = filterApartments(historicalApartments, currentFilter);

      // Ensure we're not exceeding the stack with too many apartments
      const maxApartments = 1000;
      filteredApartments = filteredApartments.slice(0, maxApartments);

      setApartments(filteredApartments);
      setCurrentNoOfApartments({
        lägenheter: filteredApartments.reduce((acc, curr) => acc + (curr.Antal || 1), 0),
        adresser: filteredApartments.length
      });

      // Calculate historical stats
      const { min, max, median } = calculateQueueDays(filteredHistorical);
      console.log('min:', min, 'max:', max, 'median:', median);
      setHistoricalApartmentCount(filteredHistorical.length);
      setHistoricalApartmentQueueDays({ min: min, max: max, median: median });
    } catch (err) {
      console.error('Error processing apartments:', err);
    }
  }, [currentFilter, allApartments, historicalApartments]);

  useEffect(() => {
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((event, session) => {
      setSession(session)
      // if (event === "PASSWORD_RECOVERY") {
      //   navigate('/login?recovery=true')
      //   return
      // }
    })
    return () => subscription.unsubscribe()
  }, [])

  const swapHistoricalAndCurrent = () => {
    if (showCurrent) {
      setAllApartments(historicalApartments);
    } else {
      setAllApartments(currentApartments);
    }
    setShowCurrent(!showCurrent);
  }

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const flashSnack = message => {
    setSnackMessage(message);
    setSnackOpen(true);
  }

  return (
    <React.Fragment>
      <CookieConsent
        location="bottom"
        buttonText="JAG FÖRSTÅR"
        cookieName="bovakaCookieConsent"
        style={{ background: "#e0f7fa", color: "black", zIndex: 9999 }}
        buttonStyle={{ background: "#1976d2", color: "white", fontSize: "13px" }}
        expires={150} z
      >

        Genom att fortsätta använda denna webbplats godkänner du vår <a href="/policy">användning av cookies</a>
      </CookieConsent>
      <AppBar style={{ backgroundColor: 'white' }} position="sticky" elevation={0}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={scrollToTop}>
            <img src={process.env.PUBLIC_URL + '/logo_transparent.png'} alt="logo" height="50" />
          </IconButton>
          <Box flexGrow={1} />
          <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleClick}>
            <MenuIcon color="action" />
          </IconButton>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            onClick={handleClose}
          >
            {session && (
              <MenuItem onClick={() => navigate('/bevakningar')}>
                Bevakningar
              </MenuItem>
            )}
            <MenuItem onClick={() => navigate('/support')}>
              Support
            </MenuItem>
            {session ? (
              <MenuItem onClick={() => {
                supabase.auth.signOut();
                flashSnack("Du är nu utloggad")
                setSession(null);
                navigate('/');
              }}>
                Logga ut
              </MenuItem>
            ) : (
              <MenuItem onClick={() => navigate('/login')}>
                Logga in
              </MenuItem>
            )}
          </Menu>
        </Toolbar>
      </AppBar>
      <Routes>
        <Route path="/success" element={<SuccessPage />} />
        <Route path="/" element={<WelcomePage />} />
        {/* <Route path="/oldlanding" element={<LandingPage />} /> */}
        <Route path="/create-subscription" element={
          <NewLandingPage
            currentFilter={currentFilter}
            setCurrentFilter={setCurrentFilter}
            historicalApartmentCount={historicalApartmentCount}
            historicalApartmentQueueDays={historicalApartmentQueueDays}
            isLoadingMediatedApartments={isLoadingMediatedApartments}
          />
        } />
        <Route path="/support" element={<SupportPage />} />
        <Route path="/login2" element={<Login supabase={supabase} setSession={setSession} />} />
        <Route path="/login" element={<Login2 />} />
        <Route path="/policy" element={<PrivacyPolicy />} />
        <Route path="/bevakningar" element={<Bevakningar supabase={supabase} setFilter={setCurrentFilter} />} />
        <Route path="/bevakningar/:magicGuid" element={<Bevakningar supabase={supabase} setFilter={setCurrentFilter} />} />
        <Route path="/map" element={
          <Map
            apartments={apartments}
            filter={currentFilter}
            setFilter={setCurrentFilter}
            supabase={supabase}
            currentNoOfApartments={currentNoOfApartments}
            filtersInUse={numberOfFiltersInUse}
            flashSnack={flashSnack}
            showCurrent={showCurrent}
            swapHistoricalAndCurrent={swapHistoricalAndCurrent}
          />} />
        <Route path="/settings" element={<SettingsPage supabase={supabase} />} />
      </Routes>
      <Snackbar open={snackOpen} autoHideDuration={4000} onClose={() => setSnackOpen(false)}>
        <Alert onClose={() => setSnackOpen(false)} severity="success" sx={{ width: '100%' }}>
          {snackMessage}
        </Alert>
      </Snackbar>
    </React.Fragment >
  );
}

export default App;
