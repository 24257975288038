import React, { useMemo, useState } from 'react';
import { Box, Autocomplete, TextField, Typography, Chip } from '@mui/material';
import omraden from '../områden.json';

export default function AreaSlider({ subscription, onAreaChange }) {
    const [inputValue, setInputValue] = useState('');
    const [resetKey, setResetKey] = useState(0);

    const omradenOptions = useMemo(() => {
        const options = omraden.map((omrade) => ({
            id: omrade.Id,
            label: omrade.LangtNamn,
            name: omrade.Namn,
        }));

        return options.sort((a, b) => a.label.localeCompare(b.label));
    }, []);

    const handleDelete = (areaToDelete) => {
        const updatedAreas = subscription.omraden.filter((area) => area.id !== areaToDelete.id);
        onAreaChange(null, updatedAreas);
    };

    return (
        <Box sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            pt: 2
        }}>
            <Box sx={{ width: '100%', maxWidth: '400px' }}>
                <Typography variant="subtitle1" gutterBottom>
                    Välj områden
                </Typography>
                <Autocomplete
                    key={resetKey}
                    id="omraden-select"
                    options={omradenOptions}
                    inputValue={inputValue}
                    onInputChange={(event, newInputValue) => {
                        setInputValue(newInputValue);
                    }}
                    onChange={(event, newValue) => {
                        if (newValue && !subscription.omraden.some(area => area.id === newValue.id)) {
                            onAreaChange(event, [...subscription.omraden, newValue]);
                            setInputValue('');
                            setResetKey(prevKey => prevKey + 1); // Force re-render
                        }
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            placeholder="Sök och välj områden"
                            fullWidth
                        />
                    )}
                    renderOption={(props, option) => {
                        return (
                            <li {...props} key={option.id}>
                                {option.label}
                            </li>
                        );
                    }}
                    filterOptions={(options, { inputValue }) => {
                        const input = inputValue.toLowerCase();
                        return options.filter(option =>
                            option.label.toLowerCase().startsWith(input) ||
                            option.name.toLowerCase().startsWith(input)
                        );
                    }}
                    sx={{ mt: 1 }}
                />
                <Box sx={{ mt: 2, display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                    {subscription.omraden.map((area) => (
                        <Chip
                            key={area.id}
                            label={area.label}
                            onDelete={() => handleDelete(area)}
                        />
                    ))}
                </Box>
            </Box>
        </Box>
    );
}