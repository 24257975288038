import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, CircularProgress } from '@mui/material';
import { motion } from 'framer-motion';
import { useNavigate, useLocation } from 'react-router-dom';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const SuccessPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // You can add any additional logic here, such as verifying the session_id
        const timer = setTimeout(() => setIsLoading(false), 2000); // Simulate loading
        return () => clearTimeout(timer);
    }, []);

    const handleContinue = () => {
        navigate('/bevakningar'); // Navigate to the subscriptions page
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                minHeight: 'calc(100vh - 64px)', // Adjust based on your AppBar height
                textAlign: 'center',
                padding: 3,
            }}
        >
            {isLoading ? (
                <CircularProgress />
            ) : (
                <motion.div
                    initial={{ opacity: 0, scale: 0.5 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 0.5 }}
                >
                    <CheckCircleOutlineIcon sx={{ fontSize: 100, color: 'success.main', mb: 2 }} />
                    <Typography variant="h4" gutterBottom>
                        Tack för ditt köp!
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Din prenumeration är nu aktiverad. Du kommer snart att få ett bekräftelsemail.
                    </Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleContinue}
                        sx={{ mt: 2 }}
                    >
                        Gå till mina bevakningar
                    </Button>
                </motion.div>
            )}
        </Box>
    );
};

export default SuccessPage;