function ToAparmentOmrade(omradeId) {
    if (omradeId.startsWith("Stadsdel-")) {
        return { Id: omradeId.replace("Stadsdel-", ""), Platstyp: 2 };
    } else if (omradeId.startsWith("Kommun-")) {
        return { Id: omradeId.replace("Kommun-", ""), Platstyp: 0 };
    } else {
        throw new Error(`Unknown omradeId: ${omradeId}`);
    }
}

const filterStartState = {
    rooms: [],
    bostadstyp: [],
    minyta: 0,
    maxyta: 0,
    omraden: [],
    minhyra: 0,
    maxhyra: 0,
    active: true
}

const parseApartmentToViewmodel = (apartment) => {
    return {
        "name": apartment.Gatuadress,
        "lat": apartment.KoordinatLatitud,
        "lng": apartment.KoordinatLongitud,
        "AntalRum": apartment.AntalRum,
        "LägstaAntalRum": apartment.LägstaAntalRum || apartment.AntalRum,
        "HögstaAntalRum": apartment.HögstaAntalRum || apartment.AntalRum,
        "Bostadstyp": parseBostadstyp(apartment),
        "Antal": apartment.Antal,
        "LägstaYtan": apartment.LägstaYtan || apartment.Yta,
        "HögstaYtan": apartment.HögstaYtan || apartment.Yta,
        "Yta": apartment.Yta,
        "LägstaHyran": apartment.LägstaHyran || apartment.Hyra,
        "HögstaHyran": apartment.HögstaHyran || apartment.Hyra,
        "Hyra": apartment.Hyra,
        "Omraden": apartment.Omraden,
        "Hiss": apartment.Hiss,
        "Balkong": apartment.Balkong,
        "Nyproduktion": apartment.Nyproduktion,
        "Stadsdel": apartment.Stadsdel,
    }
}

const parseMediatedApartmentToViewmodel = (apartment) => {
    return {
        name: apartment.Gatuadress,
        lat: apartment.Latitude,
        lng: apartment.Longitude,
        AntalRum: apartment.AntalRum,
        Bostadstyp: apartment.Bostadstyp,
        Yta: apartment.Yta,
        Hyra: apartment.Hyra,
        Omraden: [
            {
                "Platstyp": 2,
                "Id": apartment.StadsdelId
            },
            {
                "Platstyp": 0,
                "Id": apartment.KommunId
            }
        ],
        KotidDagar: apartment.KotidDagar,
        KotidDatum: apartment.KotidDatum,
        AvregistreringsDatum: apartment.AvregistreringsDatum,
        Kommun: apartment.Kommun,
        Stadsdel: apartment.Stadsdel,
        Vaning: apartment.Vaning,
        KortBeskrivning: apartment.KortBeskrivning
    };
};

const parseBostadstypMediated = (description) => {
    const lowerDesc = description.toLowerCase();
    if (lowerDesc.includes('student')) {
        return 'student';
    } else if (lowerDesc.includes('ungdom')) {
        return 'ungdom';
    } else if (lowerDesc.includes('55+') || lowerDesc.includes('65+') || lowerDesc.includes('senior')) {
        return 'senior';
    } else if (lowerDesc.includes('korttid')) {
        return 'korttid';
    } else {
        return 'vanlig';
    }
}

const parseBostadstyp = (apartment) => {
    if (apartment.Student) {
        return "student";
    } else if (apartment.Ungdom) {
        return "ungdom";
    } else if (apartment.Senior) {
        return "senior";
    } else if (apartment.Korttid) {
        return "korttid";
    } else if (apartment.BostadSnabbt) {
        return "bostadsnabbt";
    } else if (apartment.Externko) {
        return "externko";
    } else if (apartment.Vanlig) {
        return "vanlig"
    }
    return "vanlig";
}

function filterApartments(apartments, filter) {
    return apartments.filter((apartment) => {
        if (filter.rooms?.length > 0) {
            let roomMatch = false;

            const minRooms = apartment.LägstaAntalRum ?? apartment.AntalRum;
            const maxRooms = apartment.HögstaAntalRum ?? apartment.AntalRum;

            if (minRooms !== null && maxRooms !== null) {
                roomMatch = filter.rooms.some((room) => {
                    if (room === "5+") {
                        return maxRooms >= 5;
                    } else {
                        const roomNumber = parseFloat(room);
                        return roomNumber >= minRooms && roomNumber <= maxRooms;
                    }
                });
            }

            if (!roomMatch) return false;
        }

        if (filter.bostadstyp.length > 0) {
            const apartmentBostadstyp = apartment.Bostadstyp || parseBostadstyp(apartment);

            // Normalize the bostadstyp, treating "bostadsnabbt" and "korttid" as "vanlig"
            const normalizedBostadstyp = ["bostadsnabbt", "korttid"].includes(apartmentBostadstyp) ? "vanlig" : apartmentBostadstyp;

            // Check if the apartment's normalized bostadstyp matches any in the filter
            const isMatchingBostadstyp = filter.bostadstyp.includes(normalizedBostadstyp);

            // Special case: include Korttid and Bostadsnabbt apartments when filtering for "vanlig"
            const isVanligWithSpecialTypes = filter.bostadstyp.includes("vanlig") &&
                (apartment.Korttid || apartmentBostadstyp === "bostadsnabbt");

            // Exclude the apartment if it doesn't match the bostadstyp criteria
            if (!isMatchingBostadstyp && !isVanligWithSpecialTypes) {
                return false;
            }
        }
        if (filter.minyta > 0) {
            const yta = apartment.Yta || apartment.HögstaYtan
            if (yta < filter.minyta) return false;
        }

        if (filter.minhyra > 0) {
            const hyra = apartment.Hyra || apartment.HögstaHyran
            if (hyra < filter.minhyra) return false;
        }

        if (filter.maxyta > 0) {
            const yta = apartment.Yta || apartment.LägstaYtan
            if (yta > filter.maxyta) return false;
        }


        if (filter.maxhyra > 0) {
            const hyra = apartment.Hyra || apartment.LägstaHyran
            if (hyra > filter.maxhyra) return false;
        }

        if (filter.omraden?.length > 0) {
            const filterOmradeIdn = filter.omraden.map((omrade) => ToAparmentOmrade(omrade.id));
            const omradeMatch = filterOmradeIdn.some((omrade) => {
                return apartment.Omraden.some((apartmentOmrade) => {
                    return Number(omrade.Id) === apartmentOmrade.Id && omrade.Platstyp === apartmentOmrade.Platstyp;
                });
            });
            if (!omradeMatch) return false;
        }

        return true;
    });
}
function validateFilter(filter) {
    const errors = [];

    if (!filter) {
        throw new Error("Filter cannot be null or undefined.");
    }

    if (filter.rooms !== undefined && filter.rooms !== null) {
        if (!Array.isArray(filter.rooms)) {
            errors.push("`rooms` should be an array.");
        } else if (!filter.rooms.every(room => typeof room === 'string' && (room === "5+" || /^\d+$/.test(room)))) {
            errors.push("`rooms` array should only contain strings representing numbers or '5+'.");
        }
    }

    if (filter.bostadstyp !== undefined && filter.bostadstyp !== null) {
        if (!Array.isArray(filter.bostadstyp)) {
            errors.push("`bostadstyp` should be an array.");
        } else if (!filter.bostadstyp.every(type => typeof type === 'string')) {
            errors.push("`bostadstyp` array should only contain strings.");
        }
    }

    if (filter.minyta !== undefined && filter.minyta !== null) {
        if (typeof filter.minyta !== 'number' || filter.minyta < 0) {
            errors.push("`minyta` should be a non-negative number.");
        }
    }

    if (filter.minhyra !== undefined && filter.minhyra !== null) {
        if (typeof filter.minhyra !== 'number' || filter.minhyra < 0) {
            errors.push("`minhyra` should be a non-negative number.");
        }
    }

    if (filter.maxyta !== undefined && filter.maxyta !== null) {
        if (typeof filter.maxyta !== 'number' || filter.maxyta < 0) {
            errors.push("`maxyta` should be a non-negative number.");
        }
    }

    if (filter.maxhyra !== undefined && filter.maxhyra !== null) {
        if (typeof filter.maxhyra !== 'number' || filter.maxhyra < 0) {
            errors.push("`maxhyra` should be a non-negative number.");
        }
    }

    if (filter.omraden !== undefined && filter.omraden !== null) {
        if (!Array.isArray(filter.omraden)) {
            errors.push("`omraden` should be an array.");
        } else if (!filter.omraden.every(omrade => omrade && typeof omrade.id === 'string')) {
            errors.push("`omraden` array should contain objects with an `id` field of type string.");
        }
    }

    if (errors.length > 0) {
        throw new Error("Filter validation failed: " + errors.join(" "));
    }
}
module.exports = {
    ToAparmentOmrade,
    filterApartments,
    parseBostadstyp,
    validateFilter,
    filterStartState,
    parseBostadstypMediated,
    parseApartmentToViewmodel,
    parseMediatedApartmentToViewmodel
};