import React from 'react';
import { Box, ToggleButton, ToggleButtonGroup, useTheme } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import SchoolIcon from '@mui/icons-material/School';
import FaceIcon from '@mui/icons-material/Face';
import ElderlyIcon from '@mui/icons-material/Elderly';

export default function BostadstypSlider({ subscription, onBostadstypChange }) {
    const theme = useTheme();

    const bostadstyper = [
        { value: 'vanlig', label: 'Vanlig', icon: <HomeIcon /> },
        { value: 'ungdom', label: 'Ungdom', icon: <FaceIcon /> },
        { value: 'student', label: 'Student', icon: <SchoolIcon /> },
        { value: 'senior', label: 'Senior', icon: <ElderlyIcon /> },
    ];

    return (
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <ToggleButtonGroup
                value={subscription.bostadstyp}
                onChange={onBostadstypChange}
                aria-label="bostadstyp"
                sx={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2, 1fr)',
                    gap: 2,
                    width: '100%',
                    maxWidth: '300px',
                }}
            >
                {bostadstyper.map((type) => (
                    <ToggleButton
                        key={type.value}
                        value={type.value}
                        aria-label={type.label}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: '10px',
                            height: '80px',
                            border: `1px solid ${theme.palette.divider} !important`, // Ensure border on all sides
                            '&.Mui-selected': {
                                backgroundColor: theme.palette.primary.main,
                                color: theme.palette.primary.contrastText,
                                '&:hover': {
                                    backgroundColor: theme.palette.primary.dark,
                                },
                            },
                        }}
                    >
                        {type.icon}
                        <Box sx={{ mt: 1, fontSize: '0.75rem' }}>{type.label}</Box>
                    </ToggleButton>
                ))}
            </ToggleButtonGroup>
        </Box>
    );
}