// Map.js
import React from 'react';
import { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import MarkerClusterGroup from "react-leaflet-cluster";
import { useMap } from 'react-leaflet';
import { Grid, Hidden } from '@mui/material';
import { Fab, Chip, AppBar, Badge, Toolbar, IconButton, Button, Dialog, Typography, Box, Tabs, Tab, useMediaQuery } from '@mui/material';
import FilterPage from './filterpage';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { useNavigate } from 'react-router-dom';
import NotificationsIcon from '@mui/icons-material/Notifications';
import FilterListIcon from '@mui/icons-material/FilterList';
import { styled } from '@mui/material/styles';
import CustomPopup from './customPopup'


const TitleTypography = styled(Typography)({
    fontSize: '1rem',
    fontWeight: 'bold',
});

const SubTypography = styled(Typography)({
    fontSize: '0.875rem',
    color: '#555', // Adjust the color to match your design
});

const InfoTypography = styled(Typography)({
    fontSize: '0.875rem',
    fontWeight: 'bold',
});


const baseUrl = process.env.REACT_APP_BASE_URL;

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
function Map({ apartments, filter, setFilter, supabase, currentNoOfApartments, filtersInUse, flashSnack, showCurrent, swapHistoricalAndCurrent, tooManyApartments }) {
    const [filterOpen, setFilterOpen] = useState(false);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight - 2 * 56);

    const [bounds, setBounds] = React.useState([[59.95501026206206, 18.896484375000004], [58.67836548972933, 17.072753906250004]]);
    const [buttonText, setButtonText] = useState('Bevaka filtrering');
    const navigate = useNavigate();
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

    const handleBevaka = async () => {
        if (filtersInUse === 0) {
            flashSnack("Du måste välja minst ett filter för att kunna bevaka", 'error')
            return;
        }
        const session = await supabase.auth.getSession();

        if (!session.data.session) {
            navigate('/login');
            return;
        }

        setButtonText('Skapar bevakning...');
        await new Promise(resolve => setTimeout(resolve, 1000));
        const bearer = session.data.session.access_token
        fetch(`${baseUrl}/bovaka`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${bearer}`,
            },
            body: JSON.stringify(filter),
        }).then(() => {
            flashSnack('Bevakning skapad!', 'success');
            setButtonText('Bevaka filtrering')
        });
    };

    const ChangeView = ({ bounds }) => {
        const map = useMap();
        try {
            const currentBounds = map.getBounds();
            const visibleApartments = apartments.filter(apartment => currentBounds.contains([apartment.lat, apartment.lng]));
            if (visibleApartments.length > 0) {
                return null
            }
        } catch (error) {

        }
        map.fitBounds(bounds);
        return null;
    }
    React.useEffect(() => {
        if (apartments.length > 0) {
            const latitudes = apartments.map(a => a.lat);
            const longitudes = apartments.map(a => a.lng);
            const minLat = Math.min(...latitudes);
            const maxLat = Math.max(...latitudes);
            const minLng = Math.min(...longitudes);
            const maxLng = Math.max(...longitudes);
            setBounds([[minLat, minLng], [maxLat, maxLng]]);
        }
    }, [apartments]);

    const [tabValue, setTabValue] = React.useState(0);

    const handleTabChange = (event, newValue) => {
        swapHistoricalAndCurrent();
        setTabValue(newValue);
    };

    return (
        <div>
            <Grid container>
                <Grid item xs={12} md={8}>
                    <Dialog
                        fullScreen
                        open={filterOpen}
                        onClose={() => setFilterOpen(false)}
                        TransitionComponent={Transition}
                    >
                        <AppBar>
                            <Toolbar>
                                <IconButton edge="start" color="inherit" onClick={() => setFilterOpen(false)} aria-label="close">
                                    <CloseIcon />
                                </IconButton>
                                <Typography variant="h6" style={{ flex: 1 }}>
                                    Filter
                                </Typography>
                                <Button color="inherit" onClick={() => setFilterOpen(false)}>
                                    Spara
                                </Button>
                            </Toolbar>
                        </AppBar>
                        <div style={{ paddingTop: 64 }}>
                            <FilterPage filter={filter} setFilter={setFilter} supabase={supabase} currentNoOfApartments={currentNoOfApartments} />
                        </div>
                    </Dialog>
                    <div>
                        <Tabs value={tabValue} onChange={handleTabChange} variant={isMobile ? "fullWidth" : null}>
                            <Tab label="Visa akutella" style={isMobile ? { flexGrow: 1 } : null} />
                            <Tab label="Visa historik" style={isMobile ? { flexGrow: 1 } : null} />
                        </Tabs>
                        <MapContainer bounds={bounds} zoom={13} style={{ height: `${windowHeight}px`, width: "100%" }} zoomControl={false}>
                            <ChangeView bounds={bounds} />
                            <TileLayer
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            />
                            <MarkerClusterGroup>
                                {apartments.map((apartment, index) => (
                                    <Marker key={index} position={[apartment.lat, apartment.lng]}>
                                        <Popup><CustomPopup apartment={apartment} /></Popup>
                                    </Marker>
                                ))}
                            </MarkerClusterGroup>
                        </MapContainer>
                    </div>
                    <Hidden mdUp>
                        <AppBar position="fixed" style={{ top: 'auto', bottom: 0, backgroundColor: 'white' }}>
                            <Toolbar>
                                <Button style={{ color: filtersInUse === 0 ? 'grey' : 'black' }} color="inherit" onClick={handleBevaka} >
                                    <NotificationsIcon />
                                    {buttonText}
                                </Button>
                                <Box flexGrow={1} />
                                <Badge badgeContent={filtersInUse} color="secondary">
                                    <Button style={{ color: 'black' }} color="inherit" onClick={() => setFilterOpen(true)}>
                                        <FilterListIcon />
                                        Visa Filter
                                    </Button>
                                </Badge>
                            </Toolbar>
                        </AppBar>
                    </Hidden>
                </Grid>

                <Hidden smDown>
                    <Grid item md={4}>
                        <FilterPage filter={filter} setFilter={setFilter} supabase={supabase} currentNoOfApartments={currentNoOfApartments} handleBevaka={handleBevaka} buttonText={buttonText} />
                    </Grid>
                </Hidden>
            </Grid>
        </div>

    );
}

export default Map;