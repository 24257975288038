import React, { useState, useEffect, useRef } from 'react';
import {
    Box,
    Button,
    Typography,
    MobileStepper,
    useTheme,
    useMediaQuery
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

import WelcomeSlider from './sliders/welcomeslide';
import AreaSlider from './sliders/areaselect';
import RoomSlider from './sliders/roomselect';
import BostadstypSlider from './sliders/bostadstypslider';
import PriceSlider from './sliders/pricerange';
import SummarySlider from './sliders/summaryslide';
import Summary from './sliders/subscriptionsummary';
import supabase from '../misc/supabasestuff';
import { useSearchParams } from 'react-router-dom';
import omraden from './områden.json';

const steps = ['Först, välj bostadstyp:', 'OK, Vart vill du bo?', 'Hur stort ska det vara?', 'Hur dyrt får det vara?'];

const mapAreaNamesToObjects = (areaNames) => {
    return areaNames.map(areaName => {
        const match = omraden.find(o => o.Namn.toLowerCase() === areaName.toLowerCase());
        if (match) {
            return {
                id: match.Id,
                label: match.LangtNamn,
                name: match.Namn
            };
        }
        return null;
    }).filter(Boolean); // Remove any null values from failed matches
};

export default function NewLandingPage({
    currentFilter,
    setCurrentFilter,
    currentApartmentCount,
    historicalApartmentCount,
    historicalApartmentQueueDays,
    isLoadingMediatedApartments
}) {
    const [activeStep, setActiveStep] = useState(0);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [userEmail, setUserEmail] = useState('');
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [openSummaryDialog, setOpenSummaryDialog] = useState(false);
    const [searchParams] = useSearchParams();
    const initialLoadComplete = useRef(false);

    // Handle all URL parameters on initial load
    useEffect(() => {
        if (!initialLoadComplete.current) {
            // Process filter parameters
            const rooms = searchParams.get('rooms')?.split(',') || [];
            const bostadstyp = searchParams.get('bostadstyp')?.split(',') || [];
            const minyta = parseInt(searchParams.get('minyta') || '0');
            const maxyta = parseInt(searchParams.get('maxyta') || '0');
            const minhyra = parseInt(searchParams.get('minhyra') || '0');
            const maxhyra = parseInt(searchParams.get('maxhyra') || '0');

            // Parse and map areas
            let omradeObjects = [];
            try {
                const queryOmraden = searchParams.get('omraden') ?
                    JSON.parse(decodeURIComponent(searchParams.get('omraden') || '[]')) :
                    [];
                omradeObjects = mapAreaNamesToObjects(queryOmraden);
            } catch (error) {
                console.error('Error parsing omraden parameter:', error);
            }

            // Update filter if any parameters exist
            if (rooms.length || bostadstyp.length || minyta || maxyta || minhyra || maxhyra || omradeObjects.length) {
                setCurrentFilter(prev => ({
                    ...prev,
                    rooms: rooms.filter(Boolean),
                    bostadstyp: bostadstyp.filter(Boolean),
                    minyta,
                    maxyta,
                    minhyra,
                    maxhyra,
                    omraden: omradeObjects
                }));
            }

            // Process UI state parameters
            const showSummary = searchParams.get('summary') === 'true';
            if (showSummary) {
                setActiveStep(4);
                if (isMobile) {
                    setOpenSummaryDialog(true);
                }
            }

            initialLoadComplete.current = true;
        }
    }, [searchParams, isMobile, setCurrentFilter]);;

    useEffect(() => {
        const checkSession = async () => {
            const { data: { session } } = await supabase.auth.getSession();
            if (session) {
                setIsLoggedIn(true);
                setUserEmail(session.user.email);
            } else {
                setIsLoggedIn(false);
                setUserEmail('');
            }
        };
        checkSession();
    }, []);

    const handleNext = () => {
        const nextStep = activeStep + 1;
        setActiveStep(nextStep);

        // Update URL only when moving to summary step
        if (nextStep === 4) {
            const newParams = new URLSearchParams(searchParams);
            newParams.set('summary', 'true');
            navigate(`?${newParams.toString()}`, { replace: true });
        }
    };

    const handleBack = () => {
        const prevStep = activeStep - 1;
        setActiveStep(prevStep);

        // Remove summary parameter when moving back from summary step
        if (activeStep === 4) {
            const newParams = new URLSearchParams(searchParams);
            newParams.delete('summary');
            navigate(`?${newParams.toString()}`, { replace: true });
        }
    };

    // Event handlers remain the same
    const handleCreateSubscription = (email) => {
        console.log('Subscription created:', { ...currentFilter, email });
        navigate('/subscription-confirmation');
    };

    const handleAreaChange = (event, newValue) => {
        console.log(currentFilter)
        setCurrentFilter({ ...currentFilter, omraden: newValue });
    };

    const handleRoomChange = (event, newRooms) => {
        setCurrentFilter({ ...currentFilter, rooms: newRooms });
    };

    const handleMinYtaChange = (event, newValue) => {
        setCurrentFilter({ ...currentFilter, minyta: newValue });
    };

    const handleBostadstypChange = (event, newBostadstyp) => {
        setCurrentFilter({ ...currentFilter, bostadstyp: newBostadstyp });
    };

    const handlePriceChange = (event, newValue) => {
        setCurrentFilter({ ...currentFilter, maxhyra: newValue });
    };

    const getStepContent = (step) => {
        switch (step) {
            case 0:
                return <BostadstypSlider subscription={currentFilter} onBostadstypChange={handleBostadstypChange} />;
            case 1:
                return <AreaSlider subscription={currentFilter} onAreaChange={handleAreaChange} />;
            case 2:
                return <RoomSlider
                    subscription={currentFilter}
                    onRoomChange={handleRoomChange}
                    onMinYtaChange={handleMinYtaChange}
                />;
            case 3:
                return <PriceSlider
                    subscription={currentFilter}
                    onPriceChange={handlePriceChange}
                />;
            case 4: // Summary step
                return <SummarySlider
                    subscription={currentFilter}
                    onCreateSubscription={handleCreateSubscription}
                    historicalApartmentCount={historicalApartmentCount}
                    isLoggedIn={isLoggedIn}
                    userEmail={userEmail}
                    openSummaryDialog={openSummaryDialog}
                    setOpenSummaryDialog={setOpenSummaryDialog}
                />;
            default:
                return 'Okänt steg';
        }
    };

    return (
        <Box sx={{
            height: 'calc(100dvh - 64px)',
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden'
        }}>
            <Box sx={{
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                maxWidth: 600,
                width: '100%',
                margin: '0 auto',
                px: 2,
            }}>
                {activeStep < steps.length && (
                    <Box sx={{ flexShrink: 0, py: 1 }}>
                        <Summary
                            subscription={currentFilter}
                            historicalApartmentCount={historicalApartmentCount}
                            historicalApartmentQueueDays={historicalApartmentQueueDays}
                            isLoadingMediatedApartments={isLoadingMediatedApartments}
                        />
                    </Box>
                )}

                <Box sx={{ flexShrink: 0, py: 1 }}>
                    <Typography variant="h6" component="h1" align="center">
                        {activeStep < steps.length ? steps[activeStep] : ''}
                    </Typography>
                </Box>

                <Box sx={{
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    overflowY: 'auto',
                    py: 1,
                }}>
                    {getStepContent(activeStep)}
                </Box>

                <Box sx={{
                    flexShrink: 0,
                    py: 1,
                    position: 'sticky',
                    bottom: 0,
                    bgcolor: 'background.paper',
                    borderTop: '1px solid',
                    borderColor: 'divider',
                    mt: 'auto'
                }}>
                    <MobileStepper
                        variant="dots"
                        steps={steps.length}
                        position="static"
                        activeStep={Math.min(activeStep, steps.length - 1)}
                        sx={{ justifyContent: 'space-between' }}
                        nextButton={
                            <Button size="small" onClick={handleNext} disabled={activeStep === 4}>
                                Nästa
                                <KeyboardArrowRight />
                            </Button>
                        }
                        backButton={
                            <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                                <KeyboardArrowLeft />
                                Tillbaka
                            </Button>
                        }
                    />
                </Box>
            </Box>
        </Box>
    );
}