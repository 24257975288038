import React from 'react';
import MuiToggleButton from "@mui/material/ToggleButton";
import { Box, Typography, ToggleButtonGroup, FormControl, FormLabel, FormGroup, FormControlLabel, Checkbox, Select, MenuItem, Grid } from '@mui/material';
import { useState } from 'react';
import NotificationAddIcon from '@mui/icons-material/NotificationAdd';
import { Button, Hidden } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import omraden from './områden.json';
import { styled } from "@mui/material/styles";

const ToggleButton = styled(MuiToggleButton)({
    "&.Mui-selected, &.Mui-selected:hover": {
        color: "black",
        backgroundColor: '#e0f7fa'
    }
});



function FilterPage({ filter, setFilter, supabase, currentNoOfApartments, handleBevaka, buttonText }) {
    const omradenOptions = omraden.map((omrade) => { return { id: omrade.Id, label: omrade.LangtNamn, name: omrade.Namn } });
    const onOmradeChange = (event, values) => {
        setFilter((prevFilter) => {
            let newOmraden = [];
            if (values) {
                newOmraden = values.map((omrade) => ({ id: omrade.id, label: omrade.label }));
            }
            const nextState = { ...prevFilter, omraden: newOmraden };
            return nextState;
        });
    }

    const onMinHyraChange = (event) => {
        const value = event.target.value
        setFilter((prevFilter) => {
            const nextState = { ...prevFilter, minhyra: value };
            return nextState;
        });
    }

    const onMaxHyraChange = (event) => {
        const value = event.target.value
        setFilter((prevFilter) => {
            const nextState = { ...prevFilter, maxhyra: value };
            console.log(nextState)
            return nextState;
        });
    }

    const onMinYtaChange = (event) => {
        const value = event.target.value
        setFilter((prevFilter) => {
            const nextState = { ...prevFilter, minyta: value };
            return nextState;
        });
    }

    const onMaxYtaChange = (event) => {
        const value = event.target.value
        setFilter((prevFilter) => {
            const nextState = { ...prevFilter, maxyta: value };
            return nextState;
        });
    }

    const handleFormChange = (event, values) => {
        let { id, name, value, checked } = event.target;
        if (!id)
            id = name;
        if (!name)
            name = id;

        setFilter((prevFilter) => {
            let newRooms = [...prevFilter.rooms];
            let newBostadstyp = [...prevFilter.bostadstyp];

            if (id.includes('bostadstyp')) {
                id = id.split('.')[1];
                if (checked) {
                    if (!newBostadstyp.includes(id)) {
                        newBostadstyp.push(id);
                    }
                } else {
                    newBostadstyp = newBostadstyp.filter((bostadstyp) => bostadstyp !== id);
                }
                return { ...prevFilter, bostadstyp: newBostadstyp };
            } else if (name === 'minyta' || name === 'maxyta') {
                return { ...prevFilter, [name]: value }
            } else {
                if (!newRooms.includes(value))
                    newRooms.push(value);
                else
                    newRooms = newRooms.filter((room) => room !== value);
                return { ...prevFilter, rooms: newRooms };
            }
        });
    };

    return (
        <Grid container direction="column" style={{ padding: '10px' }}>
            <Grid>
                <Box bgcolor="#e0f7fa" borderRadius="8px" p={1} textAlign="center">
                    <Typography variant="body1">
                        Visar {currentNoOfApartments.lägenheter} lägenheter på {currentNoOfApartments.adresser} adresser
                    </Typography>
                </Box>
                {/* <Button
                    variant="contained"
                    color="primary"
                    startIcon={<NotificationAddIcon />}
                    onClick={handleBevaka}
                    disabled={buttonText === 'Skapar bevakning'}
                >
                    {buttonText}
                </Button> */}

            </Grid>
            <Grid item>
                <FormControl component="fieldset" style={{ width: '100%' }}>
                    <FormLabel component="legend">Område</FormLabel>
                    <Autocomplete
                        fullWidth
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        name="omraden-select"
                        multiple
                        id="omraden-select"
                        options={omradenOptions}
                        renderOption={(props, option) => {
                            return (
                                <li {...props} key={option.id}>
                                    {option.label}
                                </li>
                            );
                        }}
                        value={filter.omraden}
                        renderInput={(params) => <TextField {...params} label="Områden" />}
                        filterOptions={(options, state) => {
                            const inputValue = state.inputValue.toLowerCase();
                            const result = options.filter((option) =>
                                option.label.toLowerCase().startsWith(inputValue) || option.name.toLowerCase().startsWith(inputValue)
                            );
                            // order result alphabetically
                            result.sort((a, b) => {
                                if (a.label < b.label) {
                                    return -1;
                                }
                                if (a.label > b.label) {
                                    return 1;
                                }
                            });
                            return result;
                        }}
                        onChange={onOmradeChange}
                    />
                </FormControl>
            </Grid>
            <Grid item>
                <FormControl component="fieldset">
                    <FormLabel component="legend">Bostadstyp</FormLabel>
                    <FormGroup>
                        <FormControlLabel control={<Checkbox id="bostadstyp.vanlig" checked={filter.bostadstyp.includes('vanlig') || false} onChange={handleFormChange} />} label="Vanlig" />
                        <FormControlLabel control={<Checkbox id="bostadstyp.ungdom" checked={filter.bostadstyp.includes('ungdom') || false} onChange={handleFormChange} />} label="Ungdom" />
                        <FormControlLabel control={<Checkbox id="bostadstyp.senior" checked={filter.bostadstyp.includes('senior') || false} onChange={handleFormChange} />} label="Senior" />
                        <FormControlLabel control={<Checkbox id="bostadstyp.korttid" checked={filter.bostadstyp.includes('korttid') || false} onChange={handleFormChange} />} label="Korttid" />
                        <FormControlLabel control={<Checkbox id="bostadstyp.bostadsnabbt" checked={filter.bostadstyp.includes('bostadsnabbt') || false} onChange={handleFormChange} />} label="Bostad snabbt" />
                        <FormControlLabel control={<Checkbox id="bostadstyp.externko" checked={filter.bostadstyp.includes('externko') || false} onChange={handleFormChange} />} label="Extern kö" />
                    </FormGroup>
                </FormControl>
            </Grid>
            <Grid item>
                <FormControl fullWidth component="fieldset">
                    <FormLabel component="legend">Antal rum</FormLabel>
                    <ToggleButtonGroup fullWidth value={filter.rooms} onChange={handleFormChange} exclusive>
                        <ToggleButton id="antalrum.1" value="1" aria-label="1">
                            1
                        </ToggleButton>
                        <ToggleButton id="antalrum.2" value="2" aria-label="2">
                            2
                        </ToggleButton>
                        <ToggleButton id="antalrum.3" value="3" aria-label="3">
                            3
                        </ToggleButton>
                        <ToggleButton id="antalrum.4" value="4" aria-label="4">
                            4
                        </ToggleButton>
                        <ToggleButton id="antalrum.5" value="5+" aria-label="5+">
                            5+
                        </ToggleButton>
                    </ToggleButtonGroup>
                </FormControl>
            </Grid>
            <Grid item>
                <FormControl fullWidth component="fieldset">
                    <FormLabel component="legend">Yta min - max (kvm)</FormLabel>
                    <Grid container>
                        <Grid item xs={6}>
                            <Box mr={1}>
                                <FormControl fullWidth>
                                    <Select
                                        value={filter.minyta}
                                        onChange={onMinYtaChange}
                                        name="minyta"
                                        id="minyta"
                                    >
                                        {[...Array(filter.maxyta === 0 ? 11 : (filter.maxyta / 10) + 1)].map((_, i) => {
                                            const value = i * 10;
                                            let label = `${value} m²`
                                            if (value === 0)
                                                label = 'Inget min'
                                            return <MenuItem key={i} value={value}>{label}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box ml={1}>
                                <FormControl fullWidth>
                                    <Select
                                        value={filter.maxyta}
                                        onChange={onMaxYtaChange}
                                        name="maxyta"
                                        id="maxyta"
                                    >
                                        <MenuItem value={0}>Inget max</MenuItem>
                                        {[...Array(10 - filter.minyta / 10)].map((_, i) => {
                                            const value = (i + filter.minyta / 10 + 1) * 10;
                                            let label = `${value} m²`
                                            return <MenuItem key={i} value={value}>{label}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                            </Box>
                        </Grid>
                    </Grid>
                </FormControl>

            </Grid>
            <Grid item>
                <FormControl fullWidth component="fieldset">
                    <FormLabel component="legend">Hyra min - max</FormLabel>
                    <Grid container>
                        <Grid item xs={6}>
                            <Box mr={1}>

                                <FormControl fullWidth>
                                    <Select
                                        value={filter.minhyra}
                                        onChange={onMinHyraChange}
                                        name="minhyra"
                                        id="minhyra"
                                    >
                                        {[...Array(filter.maxhyra ? (filter.maxhyra / 2000) - 1 : 11)].map((_, i) => {
                                            const value = i * 2000;
                                            let label = `${value} kr`
                                            if (value === 0)
                                                label = 'Inget min'
                                            return <MenuItem key={i} value={value}>{label}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box ml={1}>
                                <FormControl fullWidth>
                                    <Select
                                        value={filter.maxhyra}
                                        onChange={onMaxHyraChange}
                                        name="maxhyra"
                                        id="maxhyra"
                                    >
                                        <MenuItem value={0}>Inget max</MenuItem>
                                        {[...Array(filter.minhyra ? (20000 - filter.minhyra) / 2000 : 11)].map((_, i) => {
                                            const value = (filter.minhyra ? filter.minhyra + 2000 : 0) + (i * 2000);
                                            if (value === 0) {
                                                return null; // Skip this iteration if value is 0
                                            }
                                            let label = `${value} kr`
                                            return <MenuItem key={i} value={value}>{label}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                            </Box>
                        </Grid>
                    </Grid>
                </FormControl>
                <Hidden smDown>
                    <Box mt={2}>
                        <Button variant="contained" color="primary" onClick={handleBevaka}>
                            {buttonText}
                        </Button>
                    </Box>
                </Hidden>
            </Grid>
        </Grid>
    );
}

export default FilterPage;
