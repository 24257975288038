import React from 'react';
import { Box, Typography, Button, Hidden, Stack } from '@mui/material';
import { Link } from 'react-router-dom';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

function PrivacyPolicy() {
    return (
        <Box display="flex" justifyContent="center">
            <Stack spacing={2} sx={{ maxWidth: 800, width: '100%', p: 2 }}>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ArrowDownwardIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography variant='h5'>Allmänna villkor för Bovaka</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography variant="body2" paragraph>
                            Incredible Machine AB, organisationsnummer 559490-2636
                            Bovaka.se
                            info@bovaka.se
                        </Typography>

                        <Typography variant="h6" gutterBottom>Allmänt</Typography>
                        <Typography variant="body2" paragraph>
                            Bovaka, det vill säga bovaka.se, nyttjas av bostadssökande som vill bevaka hyresbostäder och förstå hyresmarknaden bättre. Bovaka är tillgängligt för användare under förutsättning att villkoren följs. Bovaka finns tillgänglig endast för användarens personliga bostadsletande och får inte användas för andra syften utan ett tidigare skriftligt avtal.
                            Bovaka förbehåller sig rätten att löpande uppdatera villkoren, inklusive personuppgifts- och cookiepolicy, och meddela ändringarna på www.bovaka.se.

                            Som bostadssökande kan du bevaka bostäder som matchar dina sökkriterier via e-post.
                            Du kan när som helst ändra och uppdatera sökkriterierna för dina bevakningar.
                        </Typography>

                        <Typography variant="h6" gutterBottom>Nyttjanderätt</Typography>
                        <Typography variant="body2" paragraph>
                            Ditt användarkonto samt tillhörande tjänster är endast för personligt bruk. Du får inte dela, låna, sälja eller på annat sätt avslöja information om användarkontot som kan ge andra tillgång till tjänsten. Det är inte tillåtet att använda konton för automatisk eller maskinell åtkomst till data eller tjänster från Bovaka.
                        </Typography>

                        <Typography variant="h6" gutterBottom>Betalningar och abonnemang</Typography>
                        <Typography variant="body2" paragraph>
                            Alla betalningar till Bovaka baseras generellt på abonnemang, vilket innebär att ditt abonnemang fortsätter automatiskt med återkommande betalningar, tills du själv eller Bovaka säger upp det. Beloppet framgår vid tecknandet av abonnemanget, och är detsamma varje månad, såtillvida Bovaka inte meddelar en höjning eller sänkning skriftligen minst 14 dagar innan nästa planerade betalning. Varje abonnemang ger tillgång till tre olika bevakningar.
                        </Typography>

                        <Typography variant="subtitle1" gutterBottom>För att teckna ett abonnemang</Typography>
                        <Typography variant="body2" paragraph>
                            För att teckna ett abonnemang måste du vara minst 18 år gammal.
                            Tjänster som köps via vår webbplats betalas direkt till Bovaka, och avtalet hanteras därför genom vår plattform. Du betalar online med ett av våra godkända betalningsalternativ. Genom din betalning accepterar du att Bovaka får dra av det överenskomna beloppet som är i enlighet med vald tjänst från ditt betalkort.

                        </Typography>

                        <Typography variant="subtitle1" gutterBottom>Hantering av misslyckade betalningar</Typography>
                        <Typography variant="body2" paragraph>
                            Om ett betalningskort som används vid ett köp går ut eller på något sätt blockeras så att vi inte kan genomföra de periodiska betalningarna, kommer vi att försöka slutföra betalningen under en viss period därefter, beroende på orsaken till den misslyckade betalningen. Om vi inte lyckas debitera kortet inom denna period kommer abonnemanget att avslutas.
                        </Typography>

                        <Typography variant="subtitle1" gutterBottom>Byte av betalningsmetod</Typography>
                        <Typography variant="body2" paragraph>
                            Om du vill byta betalningsmetod för att fortsätta dina betalningar måste du avbryta ditt nuvarande abonnemang. När abonnemangsperioden löper ut kan du skapa en ny betalning och därmed ändra betalningsuppgifterna vid det nya köpet.
                        </Typography>

                        <Typography variant="subtitle1" gutterBottom>Översikt och kvitton</Typography>
                        <Typography variant="body2" paragraph>
                            På bovaka.se har du alltid en översikt över dina abonnemang, kommande betalningar samt tidigare betalningar. Du får ett kvitto via e-post för din första betalning när abonnemanget skapas. Ytterligare kvitton för abonnemangsförnyelser hittar du i din användarprofil. Du kan säga upp ditt abonnemang när som helst, och har då tillgång till Bovakas tjänster för den period du redan betalat för. Om du säger upp abonnemanget senare än 24h innan nästa schemalagda betalning, kan uppsägningen komma att träda i kraft efter nästa schemalagda betalning.
                        </Typography>

                        <Typography variant="h6" gutterBottom>Leverans</Typography>
                        <Typography variant="body2" paragraph>
                            Den beställda tjänsten aktiveras direkt efter betalning. Summan kommer dras från ditt kort omedelbart.
                        </Typography>

                        <Typography variant="h6" gutterBottom>Ångerrätt</Typography>
                        <Typography variant="body2" paragraph>
                            Du har 14 dagars ångerrätt på ditt köp om du inte har använt ditt abonnemang. Om du vill utnyttja ångerrätten måste du direkt efter ditt köp ta bort dina aktiva bevakningar, och inom 14 dagar skicka ett mejl till oss på info@bovaka.se. Om du använder ditt abonnemang, det vill säga har minst en aktiv mejlbevakning, så kan du inte återkalla ditt köp, utan får säga upp abonnemanget.
                        </Typography>

                        <Typography variant="h6" gutterBottom>Reklamationsrätt</Typography>
                        <Typography variant="body2" paragraph>
                            Om produkten inte kunde användas fullt ut på grund av webbplatsfel, felaktig information på hemsidan eller uteblivna mejlutskick, kan användaren få ytterligare abonnemangstid motsvarande den tiden tjänsten inte kunde användas. Detta kräver att användaren skickar dokumentation om det uppkomna felet.
                        </Typography>

                        <Typography variant="h6" gutterBottom>Ansvar</Typography>
                        <Typography variant="body2" paragraph>
                            Bovaka gör sitt yttersta för att publicerad information och mejlutskick ska vara korrekta, då det är vårt kärnuppdrag. Trots det kan det förekomma felaktiga uppgifter på bovaka.se samt i utskickade mejl. Mejlutskick kan också utebli trots att en lägenhet som matchar bevakningen har publicerats hos bostadsförmedlingen. Bovaka ansvarar inte för en eventuell förlust eller andra konsekvenser som användaren lider till följd av felaktig användning eller vid fel eller brister i tjänsten.  Bovaka ansvarar inte för påstådda eller uppkomna skador, förluster eller kostnader som direkt eller indirekt beror på eller påstås vara beroende av innehållet på bovaka.se och i mejlutskick. Bovaka ansvarar inte heller för påstådda eller uppkomna skador, förluster eller kostnader som direkt eller indirekt beror på eller påstås vara beroende på uteblivna eller ej mottagna mejlutskick.
                        </Typography>

                        <Typography variant="h6" gutterBottom>Behandling av personuppgifter</Typography>
                        <Typography variant="body2" paragraph>
                            När du nyttjar Bovaka.se registrerar vi information om dig. Insamling, behandling, lagring och radering av personuppgifter beskrivs i vår personuppgiftspolicy.
                        </Typography>

                        <Typography variant="h6" gutterBottom>Kundtjänst</Typography>
                        <Typography variant="body2" paragraph>
                            Bovakas kundtjänst tillhandahåller support via sebastian@bovaka.se. Vi hanterar ärenden i turordning och eftersträvar att slutföra ärenden inom 10 arbetsdagar. Vid hög belastning kan dock hantering ta längre tid.
                        </Typography>
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ArrowDownwardIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography variant="h5">Cookiepolicy för Bovaka</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography variant="body2" paragraph>
                            På Bovaka.com använder vi cookies för att förbättra din upplevelse på vår webbplats, analysera hur vår webbplats används och för att stödja vår marknadsföring. Denna policy förklarar vad cookies är, hur vi använder dem och dina val när det gäller dessa teknologier.                        </Typography>
                        <Typography variant="body2" paragraph>
                            Cookies innehåller vanligtvis ingen information som personligen identifierar en användare, men personlig information som vi lagrar om dig kan vara länkad till informationen som lagras i och erhålls från cookies. För ytterligare information om hur vi använder, lagrar och håller din personliga data säker, se vår Integritetspolicy. Vi lagrar inte känslig personlig information, såsom postadresser, kontolösenord, etc. i de cookies vi använder.
                        </Typography>
                        <Typography variant="h6" gutterBottom>Vad är cookies?</Typography>
                        <Typography variant="body2" paragraph>
                            Cookies är små textfiler som placeras på din enhet av webbplatser som du besöker. De används ofta för att göra webbplatser mer effektiva samt för att tillhandahålla information till webbplatsens ägare.
                        </Typography>
                        <Typography variant="h6" gutterBottom>Definitioner</Typography>
                        <Typography variant="body2" paragraph>
                            “Företag” (hänvisat till antingen som "företaget", "vi", "oss" eller "vår" i denna Cookiepolicy) avser Incredible Machine AB, Stockholm.
                            “Cookies” avser små filer som placeras på din dator, mobil enhet eller någon annan enhet av en webbplats, innehållande detaljer om din webbhistorik på den webbplatsen
                            “Webbplats” avser Bovaka, bovaka.com
                            “Du” avser den individ som använder eller besöker webbplatsen, eller ett företag, eller en juridisk enhet för vars räkning en sådan individ använder eller besöker webbplatsen, i förekommande fall.
                        </Typography>
                        <Typography variant="h6" gutterBottom>Hur använder vi cookies?</Typography>
                        <Typography variant="body2" paragraph>
                            Vi använder cookies och liknande teknologier för flera syften. Det finns två typer av cookies, det är persistent cookies (permanenta cookies) och session cookies (temporära cookies). En persistent cookie sparas under en längre tid på din enhet, men har ett utgångsdatum. En session cookie lagras tillfälligt och försvinner när du stänger webbläsaren. Vi använder också tredjeparts-cookies, det vill säga cookies som inte kommer från Bovaka.
                            Session cookies: Dessa cookies är väsentliga för att erbjuda dig tjänster som är tillgängliga via webbplatsen och för att möjliggöra användning av vissa av dess funktioner. De hjälper till att autentisera användare och förhindra bedräglig användning av användarkonton. Utan dessa cookies kan tjänsterna inte tillhandahållas, och vi använder endast dessa cookies för att tillhandahålla dig tjänsterna
                            Persistent cookies: Hjälper oss att komma ihåg dina inställningar och preferenser, såsom språk eller information du tidigare fyllt i formulär, vilket gör din nästa besök på vår webbplats smidigare.
                            Vi använder tredjeparts-cookies från New Relic och PostHog för att samla in information om hur besökare interagerar med vår webbplats. Detta inkluderar sidor som besöks, tid spenderad på webbplatsen, och eventuella problem som uppstår. Detta hjälper oss att förbättra vår webbplats och användarupplevelsen.
                        </Typography>
                        <Typography variant="h6" gutterBottom>Dina val</Typography>
                        <Typography variant="body2" paragraph>
                            Om du föredrar att undvika användning av cookies på webbplatsen, måste du först inaktivera användningen av cookies i din webbläsare och sedan radera de cookies som sparats i din webbläsare associerade med denna webbplats. Du kan använda detta alternativ för att förhindra användningen av cookies när som helst.
                            Observera att om du väljer att blockera cookies kan det påverka din användarupplevelse på vår och andra webbplatser.
                            Om du vill radera cookies eller instruera din webbläsare att radera eller vägra cookies, besök hjälpsektionerna för din webbläsare:
                            För Chrome-webbläsaren, besök denna sida från Google: support.google.com/accounts/answer/32050
                            För Internet Explorer-webbläsaren, besök denna sida från Microsoft: support.microsoft.com/kb/278835
                            För Firefox-webbläsaren, besök denna sida från Mozilla: support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored
                            För Safari-webbläsaren, besök denna sida från Apple: support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac
                            För någon annan webbläsare, besök din webbläsares officiella webbsida.
                            För mer information om hur du hanterar och raderar cookies, besök www.aboutcookies.org.
                        </Typography>
                        <Typography variant="h6" gutterBottom>Tredjepartscookies</Typography>
                        <Typography variant="body2" paragraph>
                            Observera att tredjepartstjänster, såsom New Relic och PostHog, kan använda sina egna cookies för att samla in information om dina interaktioner med vår webbplats. Informationen som samlas in av dessa tredjepartstjänster regleras av deras egna integritetspolicys.
                        </Typography>
                        <Typography variant="h6" gutterBottom>Ändringar i denna policy</Typography>
                        <Typography variant="body2" paragraph>
                            Om vi uppdaterar vår cookiepolicy kommer vi att meddela dig om eventuella ändringar genom att publicera den nya policyn på denna sida.
                        </Typography>
                        <Typography variant="h6" gutterBottom>Ändringar i denna policy</Typography>
                        <Typography variant="body2" paragraph>
                            Om du har frågor eller synpunkter, tveka inte att kontakta oss på sebastian@bovaka.com.
                        </Typography>
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ArrowDownwardIcon />}
                        aria-controls="panel3a-content"
                        id="panel3a-header"
                    >
                        <Typography variant='h5'>Integritetspolicy för Bovaka</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography variant="body2" paragraph>
                            Här förklarar vi hur vi samlar in och använder data för att följa gällande lagar och förordningar (GDPR). Informationen på denna sidan kan uppdateras. Denna integritetspolicy ändrades senast 2024-08-05.
                        </Typography>
                        <Typography variant="body2" paragraph>
                            Incredible Machine AB, organisationsnummer 559490-2636, är personuppgiftsansvarig för behandlingen av personuppgifter som beskrivs i denna integritetspolicy. I denna integritetspolicy förklarar vi vilken typ av personuppgifter vi lagrar och hur vi behandlar dem. Denna integritetspolicy gäller för: bovaka.com.
                        </Typography>

                        <Typography variant="h6" gutterBottom>Personuppgifter som vi samlar in och behandlar</Typography>
                        <Typography variant="body2" paragraph>
                            Vi behandlar följande kategorier av personuppgifter:
                        </Typography>
                        <ul>
                            <li>Kontaktinformation: e-postadress</li>
                            <li>Konto- och profilinformation: inställningar, preferenser</li>
                            <li>Betalningsinformation: Transaktionsinformation, betalningsmedel</li>
                            <li>Kundhistorik och kundengagemang: beställnings- och leveransinformation, kundvagnsrörelser, supportärenden</li>
                            <li>Aktiva produkter och avtal samt produkter och tjänster som du tidigare har haft, hur mycket och hur ofta de används, status för produkter/tjänster</li>
                            <li>Kundaktivitet: läs- och handlingshistorik från webbsidan, webbplatser eller elektronisk kommunikation vi skickar ut. Samt teknisk information om enheterna du använder.</li>
                            <li>Cookies: se vår information om cookies i vår Cookie-policy</li>
                        </ul>
                        <Typography variant="body2" paragraph>
                            Personuppgifterna samlas in direkt från dig eller genereras i samband med att du använder våra tjänster och produkter. Vi hämtar inte personuppgifter från andra källor.
                        </Typography>

                        <Typography variant="h6" gutterBottom>Hur vi använder personuppgifter</Typography>
                        <Typography variant="subtitle1" gutterBottom>Leverans av tjänst</Typography>
                        <Typography variant="body2" paragraph>
                            Vi använder dina personuppgifter för att uppfylla våra avtal med dig, det vill säga när du har beställt en produkt eller tjänst från oss. Den rättsliga grunden för att behandla personuppgifter för detta ändamål är att behandlingen är nödvändig för att uppfylla ett avtal med dig.
                        </Typography>

                        <Typography variant="subtitle1" gutterBottom>Hantering av kundrelationer</Typography>
                        <Typography variant="body2" paragraph>
                            Vi använder dina personuppgifter för att hantera vår kundrelation med dig. Det kan till exempel handla om kundtjänst, hantering av klagomål och felsökning gällande ditt konto. Den rättsliga grunden för att behandla personuppgifter för detta ändamål är att behandlingen är nödvändig för att uppfylla ett avtal med dig.
                        </Typography>

                        <Typography variant="subtitle1" gutterBottom>Analys, affärsutveckling och förbättring av tjänster</Typography>
                        <Typography variant="body2" paragraph>
                            Vi arbetar kontinuerligt med att utveckla och förbättra våra tjänster och produkter. En stor del av detta arbete innebär att analysera olika former av personuppgifter, till exempel kundaktivitet, kundhistorik och konto- och profilinformation. Den rättsliga grunden för att behandla personuppgifter för detta ändamål är vårt berättigade intresse.
                        </Typography>

                        <Typography variant="subtitle1" gutterBottom>Anpassad användarupplevelse</Typography>
                        <Typography variant="body2" paragraph>
                            Vi anpassar användarupplevelsen och kommunikationen till din kundrelation och vi använder personuppgifter för detta ändamål. Den rättsliga grunden för att behandla personuppgifter för detta ändamål är vårt berättigade intresse.
                        </Typography>

                        <Typography variant="subtitle1" gutterBottom>Försäljning och marknadsföring</Typography>
                        <Typography variant="body2" paragraph>
                            Vi använder personuppgifter i samband med försäljning och marknadsföring av våra produkter och tjänster, till exempel genom att du får e-post från oss. Den rättsliga grunden för att behandla personuppgifter för detta ändamål är vårt berättigade intresse. Du har möjlighet att reservera dig från delar av denna behandling genom att till exempel reservera dig från att få e-post från oss. Utöver detta kan vi också be dig om samtycke att använda dina personuppgifter för så kallad profilering, där vi drar slutsatser om dina intressen och behov utifrån dina personuppgifter. Syftet med profileringen är att vår marknadsföring ska bli mer relevant.
                        </Typography>

                        <Typography variant="subtitle1" gutterBottom>Systemövervakning, felsökning m.m.</Typography>
                        <Typography variant="body2" paragraph>
                            Vi övervakar våra system för fel och problem. En del av dessa processer innebär lagring och behandling av personuppgifter. Den rättsliga grunden för att behandla personuppgifter för detta ändamål är vårt berättigade intresse.
                        </Typography>

                        <Typography variant="subtitle1" gutterBottom>Följa rättsliga åtaganden</Typography>
                        <Typography variant="body2" paragraph>
                            I vissa fall är vi skyldiga att behandla personuppgifter av hänsyn till andra rättsliga åtaganden. Ett exempel på detta är information som är kopplad till försäljning, som vi är skyldiga att bokföra och spara enligt bokföringslagen. Den rättsliga grunden för att behandla personuppgifter för detta ändamål är att behandlingen är nödvändig för att uppfylla ett rättsligt åtagande som åligger oss.
                        </Typography>

                        <Typography variant="h6" gutterBottom>Dina rättigheter</Typography>
                        <Typography variant="body2" paragraph>
                            Om du vill utöva någon av dina rättigheter, kontakta oss på info@bovaka.se.
                        </Typography>

                        <Typography variant="subtitle1" gutterBottom>Rätt till insyn i egna uppgifter</Typography>
                        <Typography variant="body2" paragraph>
                            Du kan begära en kopia av alla uppgifter vi behandlar om dig. Kontakta e-postadressen ovan för att utnyttja din insynsrätt.
                        </Typography>

                        <Typography variant="subtitle1" gutterBottom>Rätt till rättelse av personuppgifter</Typography>
                        <Typography variant="body2" paragraph>
                            Du har rätt att begära att vi rättar eller kompletterar uppgifter som är felaktiga eller vilseledande.
                        </Typography>

                        <Typography variant="subtitle1" gutterBottom>Rätt till radering av personuppgifter</Typography>
                        <Typography variant="body2" paragraph>
                            Du har rätt att få dina personuppgifter raderade utan dröjsmål. Du kan när som helst begära att vi raderar uppgifter om dig själv. Men notera att information som vi är skyldiga att behålla av hänsyn till andra rättsliga åtaganden (som till exempel bokföringslagen) inte kommer att raderas.
                        </Typography>

                        <Typography variant="subtitle1" gutterBottom>Begränsning av behandling av personuppgifter</Typography>
                        <Typography variant="body2" paragraph>
                            I vissa situationer kan du också begära att vi begränsar behandlingen av uppgifter om dig. Du gör detta genom att administrera samtycken eller reservationer i våra lösningar.
                        </Typography>

                        <Typography variant="subtitle1" gutterBottom>Protestera mot behandling av personuppgifter</Typography>
                        <Typography variant="body2" paragraph>
                            Om vi behandlar uppgifter om dig med grund i våra uppgifter eller på grundval av en intresseavvägning har du rätt att protestera mot vår behandling av uppgifter om dig. Du gör detta genom att administrera samtycken eller reservationer i våra lösningar.
                        </Typography>

                        <Typography variant="subtitle1" gutterBottom>Dataportabilitet</Typography>
                        <Typography variant="body2" paragraph>
                            Du har rätt att få ut dina personuppgifter i ett strukturerat, allmänt använt och maskinläsbart format. Kontakta e-postadressen ovan för att få ut dina personuppgifter.
                        </Typography>

                        <Typography variant="h6" gutterBottom>Du kan klaga på vår behandling av personuppgifter</Typography>
                        <Typography variant="body2" paragraph>
                            Vi hoppas att du informerar oss om du anser att vi inte följer reglerna i personuppgiftslagen. Säg gärna till först genom den kontakten eller kanalen du redan har etablerat med oss. Du kan också klaga på vår behandling av personuppgifter till Integritetsskyddsmyndigheten.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </Stack>
        </Box>
    );
}

export default PrivacyPolicy;