import { Auth } from '@supabase/auth-ui-react'
import { ThemeSupa } from '@supabase/auth-ui-shared'
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Box, Stack } from '@mui/material';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import translations from './translations';

export default function Login({ supabase, setSession }) {
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const recovery = queryParams.get('recovery');

    const [authView, setAuthView] = useState(recovery === 'true' ? 'update_password' : 'sign_in');

    useEffect(() => {
        supabase.auth.onAuthStateChange((event, session) => {
            if (event === 'SIGNED_IN') {
                navigate('/');
            }
        });
    }, [supabase.auth, navigate]);

    return (
        <Container maxWidth="sm">
            <Box display="flex" justifyContent="center" alignItems="flex-start" minHeight="100vh" mt="13vh">
                <Stack>
                    <Box sx={{ minWidth: '300px' }}>
                        <Auth
                            supabaseClient={supabase}
                            appearance={{ theme: ThemeSupa }}
                            providers={[]}
                            localization={{
                                variables: translations,
                            }}
                            view={authView}
                        />
                    </Box>
                    <p>Genom att registrera dig accepterar du våran <a href="/policy">Integritetspolicy</a>.</p>
                    <Box>

                    </Box>
                </Stack>
            </Box>
        </Container>
    )
}