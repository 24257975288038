import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress, Box, Typography, Button, Chip, TextField, useTheme, useMediaQuery, FormControlLabel, Checkbox, Link } from '@mui/material';
import { loadStripe } from '@stripe/stripe-js';
const supabase = require('../../misc/supabasestuff').default;
const stripePromise = loadStripe(
    process.env.REACT_APP_USE_STRIPE_TEST_KEY === 'true'
        ? process.env.REACT_APP_STRIPE_PUBLIC_KEY_TEST
        : process.env.REACT_APP_STRIPE_PUBLIC_KEY_PROD
);
const baseUrl = process.env.REACT_APP_BASE_URL;

function SummaryContent({ subscription }) {
    const renderChipSection = (title, items, prefix = '', defaultText = '') => (
        <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                {title}
            </Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                {items && items.length > 0 ? (
                    items.map((item) => (
                        <Chip
                            key={typeof item === 'object' ? item.id : item}
                            label={typeof item === 'object' ? item.label : `${prefix}${item}`}
                            size="small"
                        />
                    ))
                ) : (
                    <Chip label={defaultText} size="small" />
                )}
            </Box>
        </Box>
    );

    return (
        <>
            {renderChipSection('Områden', subscription.omraden, '', 'Alla områden')}
            {renderChipSection('Antal rum', subscription.rooms, '', 'Alla antal rum')}
            {renderChipSection('Bostadstyper', subscription.bostadstyp, '', 'Alla typer')}
            <Box sx={{ mb: 2 }}>
                <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                    Yta och Hyra
                </Typography>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                    <Chip label={subscription.minyta > 0 ? `Min ${subscription.minyta} m²` : 'Alla ytor'} size="small" />
                    <Chip label={subscription.maxhyra > 0 ? `Max ${subscription.maxhyra} kr` : 'Alla hyror'} size="small" />
                </Box>
            </Box>
        </>
    );
}

export default function SummarySlider({ subscription, onCreateSubscription, historicalApartmentCount, isLoggedIn, userEmail, openSummaryDialog, setOpenSummaryDialog }) {
    const [email, setEmail] = useState(userEmail || '');
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [openPopup, setOpenPopup] = useState(false); // New state for handling existing subscription
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const historicalApartmentStartDate = new Date('2021-01-01');
    const today = new Date();
    const daysOfHistoricalData = Math.floor((today - historicalApartmentStartDate) / (1000 * 60 * 60 * 24));
    const yearlyMatchingApartments = Math.floor(historicalApartmentCount / daysOfHistoricalData * 365);

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!termsAccepted) {
            alert("Du måste acceptera villkoren för att fortsätta.");
            return;
        }
        setIsLoading(true);

        try {
            const stripe = await stripePromise;
            const response = await fetch(`${baseUrl}/create-checkout-session`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    email: email,
                    subscription: subscription,
                    termsAccepted: termsAccepted,
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to create subscription and checkout session');
            }

            const { id, hasActiveSubscription } = await response.json();

            if (hasActiveSubscription) {
                // If user has an active subscription, show the popup
                setOpenPopup(true);
            } else {
                const result = await stripe.redirectToCheckout({ sessionId: id });

                if (result.error) {
                    console.error(result.error);
                    alert('Det uppstod ett fel vid betalningen. Vänligen försök igen.');
                }
            }
        } catch (error) {
            console.error('Error:', error);
            alert('Det uppstod ett fel. Vänligen försök igen.');
        } finally {
            setIsLoading(false);
        }
    };

    const handleClosePopup = () => {
        setOpenPopup(false);
    };

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            p: 2,
            pb: isMobile ? 8 : 2
        }}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
                mb: 3
            }}>
                <Typography variant="h6" color="text.secondary">
                    Din bevakning matchar ca.
                </Typography>
                <Typography variant={isMobile ? "h5" : "h4"} color="primary" fontWeight="bold">
                    {yearlyMatchingApartments} lägenheter per år
                </Typography>
            </Box>

            <form onSubmit={handleSubmit}>
                {!isLoggedIn && (
                    <TextField
                        fullWidth
                        label="E-postadress"
                        type="email"
                        variant="outlined"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        sx={{ mb: 2 }}
                        required
                        disabled={isLoading}
                    />
                )}
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={termsAccepted}
                            onChange={(e) => setTermsAccepted(e.target.checked)}
                            name="termsAccepted"
                            color="primary"
                            disabled={isLoading}
                        />
                    }
                    label={
                        <Typography variant="body2">
                            Jag accepterar <Link href="/policy" target="_blank">villkoren</Link>
                        </Typography>
                    }
                />
                <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    size="large"
                    type="submit"
                    disabled={!email || !termsAccepted || isLoading}
                    sx={{ mt: 2, height: 48, mb: 2 }}
                >
                    {isLoading ? (
                        <CircularProgress size={24} color="inherit" />
                    ) : (
                        isLoggedIn ? 'Skapa Bevakning' : 'Betala 29kr/månaden'
                    )}
                </Button>
            </form>

            {isSmallScreen ? (
                <>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => setOpenSummaryDialog(true)}
                        sx={{ mb: 2 }}
                    >
                        Visa sammanfattning av bevakning
                    </Button>
                    <Dialog
                        open={openSummaryDialog}
                        onClose={() => setOpenSummaryDialog(false)}
                        fullWidth
                        maxWidth="sm"
                    >
                        <DialogTitle>Sammanfattning av din bevakning</DialogTitle>
                        <DialogContent>
                            <SummaryContent subscription={subscription} />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setOpenSummaryDialog(false)}>Stäng</Button>
                        </DialogActions>
                    </Dialog>
                </>
            ) : (
                <Box sx={{ mt: 2 }}>
                    <Typography variant="h6" gutterBottom>Sammanfattning av din bevakning</Typography>
                    <SummaryContent subscription={subscription} />
                </Box>
            )}

            {/* Popup Dialog for active subscription */}
            <Dialog open={openPopup} onClose={handleClosePopup}>
                <DialogTitle>Aktiv prenumeration finns</DialogTitle>
                <DialogContent>
                    <Typography>
                        Du verkar redan ha en aktiv prenumeration. Vi har skickat ett mail till dig där du kan logga in.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClosePopup} color="primary">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}
