import React from 'react';
import { Box, Slider, Typography, useTheme } from '@mui/material';

export default function PriceSlider({ subscription, onPriceChange }) {
    const theme = useTheme();

    return (
        <Box sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 4,
            maxWidth: '400px',
            margin: '0 auto'
        }}>
            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography variant="subtitle1" gutterBottom>
                    Maxhyra per månad
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mb: 2 }}>
                    <Typography variant="h2" component="div" color="primary" fontWeight="bold">
                        {subscription.maxhyra === 0 ? 'Alla' : subscription.maxhyra}
                    </Typography>
                    {subscription.maxhyra !== 0 && <Typography variant="h4" component="div" color="text.secondary" sx={{ ml: 1 }}>
                        kr
                    </Typography>}
                </Box>
                <Box sx={{ width: '80%', maxWidth: '300px' }}>
                    <Slider
                        value={subscription.maxhyra}
                        onChange={onPriceChange}
                        valueLabelDisplay="off"
                        step={500}
                        marks
                        min={0}
                        max={30000}
                        aria-labelledby="maxhyra-slider"
                    />
                </Box>
            </Box>
        </Box>
    );
}