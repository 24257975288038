import React from 'react';
import { Typography, Box, Chip } from '@mui/material';
import { styled } from '@mui/material/styles';

const TitleTypography = styled(Typography)({
    fontSize: '1rem',
    fontWeight: 'bold',
});

const SubTypography = styled(Typography)({
    fontSize: '0.875rem',
    color: '#555',
});

const InfoTypography = styled(Typography)({
    fontSize: '0.875rem',
    fontWeight: 'bold',
});

function CustomPopup({ apartment }) {
    const isMediated = 'KotidDatum' in apartment;

    const formatDate = (dateString) => {
        if (!dateString) return 'N/A';
        const date = new Date(dateString);
        return date.toLocaleDateString('sv-SE');
    };

    return (
        <Box>
            <TitleTypography gutterBottom>{apartment.name}</TitleTypography>
            <SubTypography>
                {apartment.Bostadstyp && `${apartment.Bostadstyp.charAt(0).toUpperCase() + apartment.Bostadstyp.slice(1)} · `}
                {apartment.Stadsdel}
            </SubTypography>

            {apartment.Vaning !== undefined && (
                <Typography variant="body2" color="textSecondary">
                    {apartment.Vaning === 'BV' ? 'Bottenvåning' : `Våning: ${apartment.Vaning}`}
                </Typography>
            )}

            <InfoTypography>
                {apartment.Yta && `${apartment.Yta} m² · `}
                {apartment.AntalRum && `${apartment.AntalRum} rum · `}
                {apartment.Hyra && `${apartment.Hyra} kr/mån`}
            </InfoTypography>

            {isMediated ? (
                <Box mt={1}>
                    <SubTypography>Förmedlad: {formatDate(apartment.KotidDatum)}</SubTypography>
                    {apartment.KotidDagar !== undefined && (
                        <SubTypography>Kötid: {Math.round(apartment.KotidDagar / 365)} år</SubTypography>
                    )}
                    {apartment.KortBeskrivning && (
                        <SubTypography>Beskrivning: {apartment.KortBeskrivning}</SubTypography>
                    )}
                </Box>
            ) : (
                <Box mt={1}>
                    {apartment.Antal > 1 && (
                        <Typography variant="body2" color="textSecondary">
                            Antal: {apartment.Antal} <br />
                            Högsta Hyra: {apartment.HögstaHyran} kr/mån <br />
                            Lägsta Hyra: {apartment.LägstaHyran} kr/mån <br />
                            Högsta Yta: {apartment.HögstaYtan} m² <br />
                            Lägsta Yta: {apartment.LägstaYtan} m² <br />
                            Lägsta Antal Rum: {apartment.LägstaAntalRum} <br />
                            Högsta Antal Rum: {apartment.HögstaAntalRum} <br />
                        </Typography>
                    )}
                </Box>
            )}

            <Box mt={1}>
                {apartment.Hiss && <Chip label="Hiss" size="small" />}
                {apartment.Balkong && <Chip label="Balkong" size="small" />}
                {apartment.Nyproduktion && <Chip label="Nyproduktion" size="small" />}
            </Box>
        </Box>
    );
}

export default CustomPopup;