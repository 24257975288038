import React, { useState } from 'react';
import {
    Box,
    TextField,
    Button,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Link
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import supabase from '../misc/supabasestuff';

const baseUrl = process.env.REACT_APP_BASE_URL;

const Login2 = () => {
    const [email, setEmail] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            const response = await fetch(`${baseUrl}/check-user`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
            });

            if (!response.ok) {
                throw new Error('Failed to check user existence');
            }

            const { exists } = await response.json();

            if (exists) {
                const { error: signInError } = await supabase.auth.signInWithOtp({
                    email,
                    options: {
                        emailRedirectTo: `${window.location.origin}/bevakningar`,
                    },
                });
                if (signInError) throw signInError;
                setSuccessMessage('Kolla din e-post för inloggningslänken!');
                setOpenSuccessDialog(true);
            } else {
                setOpenDialog(true);
            }
        } catch (error) {
            console.error('Error:', error);
            setSuccessMessage('Ett fel uppstod. Försök igen senare.');
            setOpenSuccessDialog(true);
        } finally {
            setIsLoading(false);
        }
    };

    const handleCreateAccount = async () => {
        setIsLoading(true);
        try {
            const { error } = await supabase.auth.signInWithOtp({
                email,
                options: {
                    emailRedirectTo: `${window.location.origin}/bevakningar`,
                },
            });
            if (error) throw error;
            setSuccessMessage('Kolla din e-post för att verifiera ditt konto!');
            setOpenSuccessDialog(true);
            setOpenDialog(false);
        } catch (error) {
            console.error('Error:', error);
            setSuccessMessage('Ett fel uppstod vid skapande av konto. Försök igen senare.');
            setOpenSuccessDialog(true);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Box sx={{
            maxWidth: 400,
            margin: 'auto',
            mt: 4,
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center'
        }}>
            <Typography variant="h4" component="h1" gutterBottom>
                Välkommen
            </Typography>
            <Typography variant="body1" sx={{ mb: 3 }}>
                Både nya och befintliga användare loggar in på detta sätt. Ange din e-postadress för att komma igång.
            </Typography>
            <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                <TextField
                    fullWidth
                    label="E-postadress"
                    variant="outlined"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    margin="normal"
                />
                <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={isLoading}
                    sx={{ mt: 2 }}
                >
                    {isLoading ? 'Bearbetar...' : 'Fortsätt'}
                </Button>
            </form>

            <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
                <DialogTitle>Nytt konto</DialogTitle>
                <DialogContent>
                    <Typography>
                        Den där mejladressen känner vi inte igen – så vi skapar ett nytt konto åt dig.
                    </Typography>
                    <Typography variant="body2" sx={{ mt: 2 }}>
                        Genom att fortsätta accepterar du våra{' '}
                        <Link href="/policy" target="_blank">användarvillkor</Link>.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDialog(false)}>Avbryt</Button>
                    <Button onClick={handleCreateAccount} disabled={isLoading}>
                        Skapa konto
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={openSuccessDialog} onClose={() => setOpenSuccessDialog(false)}>
                <DialogContent>
                    <Typography>{successMessage}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenSuccessDialog(false)}>OK</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default Login2;