const translations = {
    sign_in: {
        email_label: 'E-postadress',
        password_label: 'Lösenord',
        email_input_placeholder: 'Din e-postadress',
        password_input_placeholder: 'Ditt lösenord',
        button_label: 'Logga in',
        loading_button_label: 'Loggar in ...',
        link_text: 'Har du redan ett konto? Logga in istället',
    },
    sign_up: {
        email_label: 'E-postadress',
        password_label: 'Skapa lösenord',
        email_input_placeholder: 'Din e-postadress',
        button_label: 'Skapa konto',
        loading_button_label: 'Skickar e-post ...',
        link_text: 'Skapa konto',
        confirmation_text: 'Kontrollera din e-post för en bekräftelselänk',
    },
    forgotten_password: {
        email_label: 'E-postadress',
        email_input_placeholder: 'Din e-postadress',
        password_label: 'Nytt lösenord',
        password_input_placeholder: 'Ditt nya lösenord',
        button_label: 'Uppdatera lösenord',
        loading_button_label: 'Uppdaterar lösenord ...',
        confirmation_text: 'Ditt lösenord har uppdaterats',
        link_text: 'Glömt lösenord?',

    },
}

export default translations;