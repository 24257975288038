import React, { useState, useEffect } from 'react';
import { Grid, useTheme, useMediaQuery, Alert, List, Button, Card, CardContent, Typography, Box, CardActions, CardHeader, IconButton, FormControlLabel, Checkbox, CircularProgress } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useParams } from 'react-router-dom';
import { Routes, Route, useNavigate, Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { motion, AnimatePresence } from 'framer-motion';

const baseUrl = process.env.REACT_APP_BASE_URL;

function BevakningarPage({ supabase, setFilter }) {
    const [bevakningar, setBevakningar] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [deletingId, setDeletingId] = useState(null);
    const [isManagingSubscription, setIsManagingSubscription] = useState(false);
    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const { magicGuid } = useParams();
    const MAX_ACTIVE_BEVAKNINGAR = 3;
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [isPayingCustomer, setIsPayingCustomer] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            let bearer;
            if (magicGuid) {
                bearer = magicGuid;
            } else {
                const { data: { session } } = await supabase.auth.getSession();
                if (session) {
                    bearer = session.access_token;
                    setIsLoggedIn(true);

                    // Fetch user info to check if they're a paying customer
                    try {
                        const response = await fetch(`${baseUrl}/userinfo`, {
                            headers: {
                                'Authorization': `Bearer ${bearer}`
                            }
                        });
                        if (response.ok) {
                            const userInfo = await response.json();
                            setIsPayingCustomer(userInfo.stripeCustomerId !== null);
                        }
                    } catch (error) {
                        console.error('Error fetching user info:', error);
                    }
                } else {
                    navigate('/login');
                    return;
                }
            }

            try {
                const response = await fetch(`${baseUrl}/bevakningar${magicGuid ? `?magicGuid=${magicGuid}` : ''}`, {
                    headers: {
                        'Authorization': `Bearer ${bearer}`
                    }
                });
                if (response.ok) {
                    const data = await response.json();
                    setBevakningar(data);
                } else {
                    console.error('Failed to fetch bevakningar');
                }
            } catch (error) {
                console.error('Error:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [magicGuid, supabase.auth, navigate]);

    const deleteBevakning = async (id) => {
        setDeletingId(id);
        let bearer;
        if (magicGuid) {
            bearer = magicGuid;
        } else {
            const { data: { session } } = await supabase.auth.getSession();
            bearer = session.access_token;
        }

        try {
            const response = await fetch(`${baseUrl}/bevakningar/${id}${magicGuid ? `?magicGuid=${magicGuid}` : ''}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${bearer}`
                }
            });
            if (response.ok) {
                setBevakningar(bevakningar => bevakningar.filter(bevakning => bevakning.id !== id));
            } else {
                console.error('Failed to delete bevakning');
            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setDeletingId(null);
        }
    }

    const setBevakning = (bevakning) => {
        bevakning = {
            rooms: bevakning.rooms,
            bostadstyp: bevakning.bostadstyp,
            minyta: bevakning.minyta,
            maxyta: bevakning.maxyta,
            minhyra: bevakning.minhyra,
            maxhyra: bevakning.maxhyra,
            omraden: bevakning.omraden ? bevakning.omraden : []
        }
        setFilter(bevakning);
        navigate('/map')
    }

    const formatStorlek = (minyta, maxyta) => {
        if (minyta === 0 && maxyta === 0) {
            return 'Alla storlekar';
        }
        if (minyta === 0)
            return `Upp till ${maxyta} kvm`;
        if (maxyta === 0)
            return `Från ${minyta} kvm`;
        return `${minyta} - ${maxyta} kvm`;
    }

    const formatHyra = (minhyra, maxhyra) => {
        if (minhyra === 0 && maxhyra === 0) {
            return 'Alla hyror';
        }
        if (minhyra === 0)
            return `Upp till ${maxhyra} kr`;
        if (maxhyra === 0)
            return `Från ${minhyra} kr`;
        return `${minhyra} - ${maxhyra} kr`;
    }

    const setActive = async (id, active) => {
        let bearer;
        if (magicGuid) {
            bearer = magicGuid;
        } else {
            const { data: { session } } = await supabase.auth.getSession();
            bearer = session.access_token;
        }

        try {
            const activeCount = bevakningar.filter(b => b.active).length;
            if (active && activeCount >= MAX_ACTIVE_BEVAKNINGAR) {
                alert(`Du kan bara ha ${MAX_ACTIVE_BEVAKNINGAR} aktiva bevakningar. Inaktivera en annan bevakning först.`);
                return;
            }

            const response = await fetch(`${baseUrl}/bevakningar/${id}/set-active${magicGuid ? `?magicGuid=${magicGuid}` : ''}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${bearer}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ active })
            });
            if (response.ok) {
                const updatedSubscription = await response.json();
                setBevakningar(bevakningar.map(bevakning =>
                    bevakning.id === id ? { ...bevakning, active: updatedSubscription.active } : bevakning
                ));
            } else {
                console.error('Failed to set subscription active state');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    }

    const handleManageSubscription = async () => {
        setIsManagingSubscription(true);
        try {
            const { data: { session } } = await supabase.auth.getSession();
            if (!session) {
                console.error('User not logged in');
                return;
            }

            const response = await fetch(`${baseUrl}/create-portal-session`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${session.access_token}`,
                    'Content-Type': 'application/json',
                },
            });

            if (response.ok) {
                const { url } = await response.json();
                window.location.href = url;
            } else {
                console.error('Failed to create Stripe Customer Portal session');
            }
        } catch (error) {
            console.error('Error creating Stripe Customer Portal session:', error);
        } finally {
            setIsManagingSubscription(false);
        }
    };

    const activeBevakningonsCount = bevakningar.filter(b => b.active).length;

    const cardVariants = {
        visible: { opacity: 1, height: 'auto' },
        hidden: { opacity: 0, height: 0 }
    };

    if (isLoading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Box sx={{ maxWidth: 800, margin: 'auto', padding: 2 }}>
            <Grid container spacing={2} alignItems="center" mb={2}>
                <Grid item xs={12} sm={6}>
                    <Typography variant="h4">Mina bevakningar</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Button
                        variant="contained"
                        color="primary"
                        component={Link}
                        to="/"
                        startIcon={<AddIcon />}
                        fullWidth={isMobile}
                    >
                        Skapa ny bevakning
                    </Button>
                </Grid>
            </Grid>

            {activeBevakningonsCount >= MAX_ACTIVE_BEVAKNINGAR && (
                <Alert severity="info" sx={{ mb: 2 }}>
                    Du har nått gränsen på {MAX_ACTIVE_BEVAKNINGAR} aktiva bevakningar.
                </Alert>
            )}

            {isLoggedIn && isPayingCustomer && !magicGuid && (
                <Box textAlign="center" mb={2}>
                    <Button
                        onClick={handleManageSubscription}
                        variant="outlined"
                        fullWidth={isMobile}
                        disabled={isManagingSubscription}
                    >
                        {isManagingSubscription ? <CircularProgress size={24} /> : 'Hantera prenumeration'}
                    </Button>
                </Box>
            )}

            {magicGuid && (
                <Alert severity="info" sx={{ mb: 2 }}>
                    För att hantera betalningar måste du <Link to="/login">logga in</Link>
                </Alert>
            )}

            <AnimatePresence>
                {bevakningar.map((bevakning) => (
                    <motion.div
                        key={bevakning.id}
                        initial="visible"
                        animate="visible"
                        exit="hidden"
                        variants={cardVariants}
                        transition={{ duration: 0.3 }}
                    >
                        <Card variant="outlined" style={{ marginBottom: '10px', overflow: 'hidden' }}>
                            <CardHeader
                                action={
                                    <Box display="flex" alignItems="center">
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={bevakning.active}
                                                    onChange={(e) => setActive(bevakning.id, e.target.checked)}
                                                    color="primary"
                                                />
                                            }
                                            label="Aktiv"
                                        />
                                        <IconButton
                                            onClick={() => deleteBevakning(bevakning.id)}
                                            aria-label="delete"
                                            disabled={deletingId === bevakning.id}
                                        >
                                            {deletingId === bevakning.id ? <CircularProgress size={24} /> : <DeleteIcon />}
                                        </IconButton>
                                    </Box>
                                }
                                title={bevakning.omraden.map(omrade => omrade.label).join(', ') || 'Alla områden'}
                                titleTypographyProps={{ variant: 'body1' }}
                            />
                            <CardContent style={{ paddingTop: 0 }}>
                                <Typography variant="body2" color="text.secondary">
                                    Antal rum: {bevakning.rooms.length > 0 ? bevakning.rooms.join(', ') : 'Alla rum'}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Bostadstyper: {bevakning.bostadstyp.length > 0 ? bevakning.bostadstyp.join(', ') : 'Vanlig bostadstyp'}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Storlek: {formatStorlek(bevakning.minyta, bevakning.maxyta)}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Hyra: {formatHyra(bevakning.minhyra, bevakning.maxhyra)}
                                </Typography>
                            </CardContent>
                        </Card>
                    </motion.div>
                ))}
            </AnimatePresence>
        </Box>
    );
}

export default BevakningarPage;