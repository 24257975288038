import React from 'react';
import { Box, ToggleButton, ToggleButtonGroup, Slider, Typography, useTheme } from '@mui/material';

export default function RoomAndAreaSlider({ subscription, onRoomChange, onMinYtaChange }) {
    const theme = useTheme();

    const rooms = ['1', '2', '3', '4', '5+'];

    return (
        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 4 }}>
            <Box sx={{ width: '100%', maxWidth: '400px' }}>
                <Typography variant="subtitle1" gutterBottom>
                    Antal rum
                </Typography>
                <ToggleButtonGroup
                    value={subscription.rooms}
                    onChange={onRoomChange}
                    aria-label="antal rum"
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                    }}
                >
                    {rooms.map((room) => (
                        <ToggleButton
                            key={room}
                            value={room}
                            aria-label={`${room} rum`}
                            sx={{
                                flex: 1,
                                padding: '8px',
                                border: `1px solid ${theme.palette.divider} !important`,
                                '&.Mui-selected': {
                                    backgroundColor: theme.palette.primary.main,
                                    color: theme.palette.primary.contrastText,
                                    '&:hover': {
                                        backgroundColor: theme.palette.primary.dark,
                                    },
                                },
                            }}
                        >
                            {room}
                        </ToggleButton>
                    ))}
                </ToggleButtonGroup>
            </Box>

            <Box sx={{ width: '100%', maxWidth: '400px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography variant="subtitle1" gutterBottom>
                    Minsta yta
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mb: 2 }}>
                    <Typography variant="h2" component="div" color="primary" fontWeight="bold">
                        {subscription.minyta === 0 ? 'Alla' : subscription.minyta}
                    </Typography>
                    {subscription.minyta !== 0 && (
                        <Typography variant="h4" component="div" color="text.secondary" sx={{ ml: 1 }}>
                            m²
                        </Typography>
                    )}
                </Box>
                <Box sx={{ width: '80%', maxWidth: '300px' }}> {/* Adjusted width for the slider */}
                    <Slider
                        value={subscription.minyta}
                        onChange={onMinYtaChange}
                        valueLabelDisplay="auto"
                        step={5}
                        marks
                        min={0}
                        max={150}
                        aria-labelledby="minyta-slider"
                    />
                </Box>
            </Box>
        </Box>
    );
}