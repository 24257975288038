import React, { useState, useEffect } from 'react';
import { Card, CardContent, Box, Typography, Button, useTheme, useMediaQuery, Paper, Grid } from '@mui/material';
import { useNavigate, Link } from 'react-router-dom';
import AssignmentIcon from '@mui/icons-material/Assignment';
import PaymentIcon from '@mui/icons-material/Payment';
import EmailIcon from '@mui/icons-material/Email';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SearchIcon from '@mui/icons-material/Search';
import { alpha } from '@mui/material/styles';
import supabase from '../misc/supabasestuff';


const Arrow = ({ direction = 'right' }) => {
    const theme = useTheme();
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                color: theme.palette.primary.main,
            }}
        >
            <ArrowForwardIcon
                fontSize="large"
                sx={{
                    transform: direction === 'down' ? 'rotate(90deg)' : 'none',
                }}
            />
        </Box>
    );
};

export default function WelcomeLandingPage() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const hurFungerarSection = React.useRef(null);

    const scrollToHurFungerar = () => {
        if (hurFungerarSection.current) {
            const yOffset = -64; // Negative value to scroll up by the AppBar's height
            const y = hurFungerarSection.current.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
        }
    };

    const contactInfo = {
        companyName: "Incredible Machine AB",
        orgNumber: "559490-2636",
        email: "sebastian@bovaka.com"
    };



    useEffect(() => {
        // Check if the user is logged in
        const checkSession = async () => {
            const { data: { session } } = await supabase.auth.getSession();
            if (session) {
                setIsLoggedIn(true);
            } else {
                setIsLoggedIn(false);
            }
        };
        checkSession();
    }, []);


    // Create a very light version of the primary color
    const veryLightPrimary = alpha(theme.palette.primary.main, 0.15);

    const handleGetStarted = () => {
        navigate('/create-subscription'); // Navigate to the subscription creation process
    };

    const features = [
        {
            icon: <AccessTimeIcon fontSize="large" />,
            title: "Du som vill maximera dina ködagar:",
            description: "Ansök på alla lägenheter som matchar dina preferenser och fånga det där riktiga kapet!"
        },
        {
            icon: <SearchIcon fontSize="large" />,
            title: "Du som verkligen vet vad du letar efter:",
            description: "Slipp besöka Stockholms Bostadsförmedling varje dag för lägen som sällan dyker upp. Vi meddelar dig när det är dags."
        }
    ];


    const steps = [
        {
            icon: <AssignmentIcon fontSize="large" />,
            text: "Välj bostadstyp och se historisk statistik för dina preferenser"
        },
        {
            icon: <PaymentIcon fontSize="large" />,
            text: "Betala endast 29 kr per månad för tjänsten"
        },
        {
            icon: <SearchIcon fontSize="large" />,
            text: "Bovaka bevakar automatiskt Stockholms Bostadsförmedling åt dig"
        },
        {
            icon: <EmailIcon fontSize="large" />,
            text: "Få e-postnotifieringar när en matchande lägenhet blir tillgänglig"
        }
    ];

    return (
        <Box>
            {/* WelcomeSlider-like section */}
            <Box sx={{
                width: '100%',
                height: `calc(85vh - ${64}px)`,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
                textAlign: 'center',
                padding: 3,
                paddingBottom: 0,
                bgcolor: 'background.paper',
            }}>
                <Box
                    flexGrow={1}
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    sx={{ marginTop: '-60px' }}
                >
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 4,
                        maxWidth: '600px',
                        width: '100%',
                    }}>
                        <Typography variant="h4" component="h1">
                            Bevaka lägenheter i Stockholms Bostadsförmedling
                        </Typography>
                        <Typography variant="body1">
                            Ange dina önskemål en gång och få mail direkt när nya hyresrätter dyker upp.
                        </Typography>
                    </Box>
                </Box>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: 2,
                    marginBottom: '120px',
                    width: isMobile ? '100%' : 'auto',
                }}>
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        onClick={handleGetStarted}
                        fullWidth={isMobile}
                        sx={{ width: isMobile ? '100%' : '500px' }}
                    >
                        Kom igång nu!
                    </Button>
                    {!isLoggedIn && (
                        <Button
                            variant="outlined"
                            color="primary"
                            size="large"
                            fullWidth={isMobile}
                            onClick={scrollToHurFungerar}
                            sx={{ width: isMobile ? '100%' : '500px' }}
                        >
                            Hur fungerar Bovaka?
                        </Button>
                    )}
                </Box>
            </Box>

            <Box
                ref={hurFungerarSection}
                sx={{
                    width: '100%',
                    bgcolor: veryLightPrimary,
                    py: 6,
                }}
            >
                <Box sx={{
                    maxWidth: '1200px',
                    margin: '0 auto',
                    width: '100%',
                    px: 3,
                }}>
                    <Typography variant="h5" gutterBottom align="center" sx={{ mb: 4 }}>
                        Hur fungerar Bovaka?
                    </Typography>
                    <Grid container spacing={2} alignItems="center" justifyContent="center">
                        {steps.map((step, index) => (
                            <React.Fragment key={index}>
                                <Grid item xs={12} sm={6} md={2.5}> {/* Adjusted Grid item size */}
                                    <Box display="flex" flexDirection="column" alignItems="center" textAlign="center">
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                width: 80,
                                                height: 80,
                                                borderRadius: '50%',
                                                bgcolor: theme.palette.primary.main,
                                                color: theme.palette.primary.contrastText,
                                                mb: 2,
                                            }}
                                        >
                                            {step.icon}
                                        </Box>
                                        <Typography variant="body1">
                                            {step.text}
                                        </Typography>
                                    </Box>
                                </Grid>
                                {index < steps.length - 1 && (
                                    <Grid item xs={12} sm={1} md={0.5} sx={{ display: { xs: 'none', md: 'block' } }}>
                                        <Arrow direction="right" />
                                    </Grid>
                                )}
                                {index < steps.length - 1 && isMobile && (
                                    <Grid item xs={12}>
                                        <Arrow direction="down" />
                                    </Grid>
                                )}
                            </React.Fragment>
                        ))}
                    </Grid>
                </Box>
            </Box>

            <Box sx={{
                width: '100%',
                bgcolor: 'background.paper',
                py: 6,
            }}>
                <Box sx={{
                    maxWidth: '800px',
                    margin: '0 auto',
                    width: '100%',
                    px: 3,
                }}>
                    <Typography variant="h4" gutterBottom align="center" sx={{ mb: 4 }}>
                        Varför behövs Bovaka?
                    </Typography>
                    <Box display="flex" alignItems="center" mb={4}>
                        <Typography variant="body1" component="div">
                            Stockholms Bostadsförmedling publicerar{' '}
                            <Typography
                                component="span"
                                variant="body1"
                                sx={{
                                    fontWeight: 'bold',
                                    color: theme.palette.primary.main,
                                }}
                            >
                                tusentals hyresrätter
                            </Typography>{' '}
                            varje månad, ofta med{' '}
                            <Typography
                                component="span"
                                variant="body1"
                                sx={{
                                    fontWeight: 'bold',
                                    color: theme.palette.primary.main,
                                }}
                            >
                                bara 1-3 dagars ansökningstid.
                            </Typography>
                        </Typography>
                    </Box>

                    <Grid container spacing={4}>
                        {features.map((feature, index) => (
                            <Grid item xs={12} sm={6} key={index}>
                                <Box display="flex" flexDirection="column" alignItems="center" textAlign="center">
                                    <Box sx={{ color: theme.palette.primary.main, mb: 0 }}>
                                        {feature.icon}
                                    </Box>
                                    <Typography variant="h6" gutterBottom>
                                        {feature.title}
                                    </Typography>
                                    <Typography variant="body2">
                                        {feature.description}
                                    </Typography>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>


                </Box>
            </Box>
            {/* New "Om oss" section */}
            <Box sx={{
                width: '100%',
                bgcolor: veryLightPrimary,
                py: 6,
                minHeight: '30vh', // This ensures it takes up at least 30% of the viewport height
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
            }}>
                <Box sx={{
                    maxWidth: '800px',
                    margin: '0 auto',
                    width: '100%',
                    px: 3,
                }}>
                    <Typography variant="h5" align="center" gutterBottom>
                        Om oss
                    </Typography>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: 1,
                    }}>
                        <Typography variant="body1" align="center">
                            Bovaka är en tjänst av {contactInfo.companyName}
                        </Typography>
                        <Typography variant="body2" align="center">
                            Org-nummer: {contactInfo.orgNumber}
                        </Typography>
                        <Typography variant="body2" align="center">
                            Kontakt: {contactInfo.email}
                        </Typography>
                        <Typography variant="body2" align="center">
                            <Link to="/policy">Villkor</Link>
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}