import React from 'react';
import { Box, Typography, Button, Link } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';

function SupportPage() {
    return (
        <Box
            sx={{
                maxWidth: 600,
                margin: 'auto',
                padding: 3,
                textAlign: 'center',
            }}
        >
            <Typography variant="h4" component="h1" gutterBottom>
                Support
            </Typography>
            <Typography variant="body1" paragraph>
                Behöver du hjälp med något? Kontakta oss!
            </Typography>
            <Typography variant="body1" paragraph>
                Du når oss på e-post:
            </Typography>
            <Button
                variant="contained"
                color="primary"
                startIcon={<EmailIcon />}
                component={Link}
                href="mailto:sebastian@bovaka.com"
                target="_blank"
                rel="noopener noreferrer"
                sx={{ mt: 2 }}
            >
                sebastian@bovaka.com
            </Button>
        </Box>
    );
}

export default SupportPage;